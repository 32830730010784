import { FOOTER_ITEMS, GIT_COMMIT_HASH, BUILD_DATE } from "#env/env-vars";
import { DescriptionList, DescriptionSection } from "#components/lists";
import { Preformatted } from "#components/formatting";

import * as styles from "./footer.module.scss";

export function GlobalFooter() {
  return (
    <footer className="global-footer">
      {GIT_COMMIT_HASH && (
        <DescriptionList>
          <DescriptionSection
            dKey="Version"
            dValue={
              <Preformatted className={styles.version} title={`Running commit: ${GIT_COMMIT_HASH.slice(0, 9)}`}>
                {BUILD_DATE}
              </Preformatted>
            }
          />
        </DescriptionList>
      )}

      <ul className="footer">
        {FOOTER_ITEMS?.map((item, index) => (
          <li
            key={index}
            dangerouslySetInnerHTML={{ __html: String(item) }}
          ></li>
        ))}
      </ul>
    </footer>
  );
}
