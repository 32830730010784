import { IBlockProps, createBlockComponent } from "#components/meta";

import * as styles from "./pre.module.scss";

interface IProps extends IBlockProps<"pre"> {}

export const Preformatted = createBlockComponent(styles, Component)

function Component({...props}:IProps) {
  return <pre {...props} />
}
