import {
  fetchLoginAccount,
  fetchLogoutAccount,
  fetchRegisterAccount,
} from "#api/authentication";
import {
  deleteLocalStorageItem,
  getLocalStorageItem,
  setLocalStorageItem,
} from "#storage/local";

export async function registerAccount(
  ...args: Parameters<typeof fetchRegisterAccount>
) {
  const [username, password] = args;
  await fetchRegisterAccount(...args);
  const account = await fetchLoginAccount(username, password);

  setLocalStorageItem("logged_in", "yes");
  setLocalStorageItem("role", account.role);

  return account;
}

export async function loginAccount(
  ...args: Parameters<typeof fetchLoginAccount>
) {
  const account = await fetchLoginAccount(...args);

  setLocalStorageItem("logged_in", "yes");
  setLocalStorageItem("role", account.role);

  return account;
}

export async function logoutAccount(isLocalOnly?: boolean) {
  if (!isLocalOnly) {
    await fetchLogoutAccount();
  }

  deleteLocalStorageItem("logged_in");
  deleteLocalStorageItem("role");
  deleteLocalStorageItem("favs");
  deleteLocalStorageItem("post_favs");

  return true;
}

export function isRegisteredAccount() {
  return getLocalStorageItem("logged_in") === "yes";
}
