import { useRouteError, isRouteErrorResponse } from "react-router";
import { DescriptionList, DescriptionSection } from "#components/lists";
import { Preformatted } from "#components/formatting";
import { ErrorView } from "#components/errors";
import { PageSkeleton } from "./site";

import * as styles from "./error.module.scss";

export function ErrorPage() {
  const error = useRouteError();
  const isRouterError = isRouteErrorResponse(error);
  const title = isRouterError ? "Route error" : "Error";
  const heading = isRouterError ? "Route Error" : "Error";
  console.error(error);

  return (
    <PageSkeleton name="" title={title} heading={heading}>
      <article className={styles.page}>
        {isRouterError ? (
          <DescriptionList>
            <DescriptionSection
              dKey="Status"
              dValue={<Preformatted>{error.status}</Preformatted>}
              isHorizontal
            />
            <DescriptionSection
              dKey="Message"
              dValue={<Preformatted>{error.statusText}</Preformatted>}
              isHorizontal
            />
            <DescriptionSection
              dKey="Extra Details"
              dValue={String(error.data)}
            />
          </DescriptionList>
        ) : (
          <ErrorView error={error} />
        )}
      </article>
    </PageSkeleton>
  );
}
