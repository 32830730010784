import { apiFetch } from "#lib/api";

interface IArtistData {
  service: string;
  artist_id: string;
}

export async function fetchRandomArtist(): Promise<IArtistData> {
  const result = await apiFetch<IArtistData>("/artists/random", {
    method: "GET",
  });

  return result;
}
