import { useLocation } from "react-router";
import { HEADER_AD, MIDDLE_AD, FOOTER_AD, SLIDER_AD } from "#env/env-vars";
import { DangerousContent } from "#components/dangerous-content";
import { useEffect } from "react";

export function HeaderAd() {
  const location = useLocation();
  const key = `${location.pathname}${location.search}`;

  return !HEADER_AD ? undefined : (
    <DangerousContent
      key={key}
      className="ad-container"
      html={atob(HEADER_AD)}
    />
  );
}

export function MiddleAd() {
  const location = useLocation();
  const key = `${location.pathname}${location.search}`;

  return !MIDDLE_AD ? undefined : (
    <DangerousContent
      key={key}
      className="ad-container"
      html={atob(MIDDLE_AD)}
      allowRerender
    />
  );
}

export function FooterAd() {
  const location = useLocation();
  const key = `${location.pathname}${location.search}`;

  return !FOOTER_AD ? undefined : (
    <DangerousContent
      key={key}
      className="ad-container"
      html={atob(FOOTER_AD)}
      allowRerender
    />
  );
}

export function SliderAd() {
  const location = useLocation();
  const key = `${location.pathname}${location.search}`;

  const observer = new MutationObserver((mutationsList) => {
    for (const mutation of mutationsList) {
      if (mutation.type === "childList") {
        const slideAnimationElements = document.querySelectorAll('[class*="slideAnimation"]');
        const elementsToRemove = Array.from(slideAnimationElements).slice(1);
        elementsToRemove.forEach((element) => {
          element.remove();
        });
      }
    }
  });

  observer.observe(document.body, { childList: true, subtree: true });

  useEffect(() => {
    return () => {
      observer.disconnect();
      document.querySelectorAll('[class*="slideAnimation"]').forEach((element) => {
        element.remove();
      });
    };
  }, []);

  return !SLIDER_AD ? undefined : (
    <DangerousContent
      key={key}
      className="ad-container-slider"
      html={atob(SLIDER_AD)}
      allowRerender
    />
  );
}
