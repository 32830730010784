import clsx from "clsx";
import { createAccountDetailsPageURL } from "#lib/urls";
import { Timestamp } from "#components/dates";
import { DescriptionList, DescriptionSection } from "#components/lists";
import { LinkButton } from "#components/links";
import { IAccount } from "./types";

import * as styles from "./preview.module.scss";

interface IProps {
  account: IAccount;
}

export function AccountPreview({ account }: IProps) {
  const { id, username, role, created_at } = account;

  return (
    <article className={clsx("account-card", styles.block)}>
      <header>
        <h2>{username}</h2>
      </header>

      <section className={styles.body}>
        <DescriptionList>
          <DescriptionSection dKey="Role" dValue={role} isHorizontal />

          <DescriptionSection
            dKey="Joined"
            dValue={<Timestamp time={created_at} />}
          />
        </DescriptionList>
      </section>

      <footer>
        <LinkButton url={String(createAccountDetailsPageURL(id))}>
          Overview
        </LinkButton>
      </footer>
    </article>
  );
}
