import {
  LoaderFunctionArgs,
  useLoaderData,
  useNavigate,
} from "react-router";
import { apiFetchArchiveFile, apiSetArchiveFilePassword } from "#api/files";
import { PageSkeleton } from "#components/pages";
import { IS_FILE_SERVING_ENABLED } from "#env/env-vars";
import { createArchiveFileURL } from "#lib/urls";
import { KemonoLink } from "#components/links";
import { IArchiveFile } from "#api/files";
import { FormEvent, useState } from "react";

import * as styles from "./archive.module.scss";

interface IProps {
  archive: IArchiveFile;
}

interface IFileItemProps {
  name: string;
  archiveHash: string;
  archiveExtension: string;
  password?: string;
}

function FileItem({
  name,
  archiveHash,
  archiveExtension,
  password,
}: IFileItemProps) {
  return (
    <li>
      {!IS_FILE_SERVING_ENABLED ? (
        name
      ) : (
        <KemonoLink
          url={String(
            createArchiveFileURL(archiveHash, archiveExtension, name, password)
          )}
        >
          {name}
        </KemonoLink>
      )}
    </li>
  );
}

export function ArchiveFilePage() {
  const { archive: { file: { hash, ext }, file_list, password: loaderPassword } } = useLoaderData() as IProps;
  const title = `Archive file "${hash}" details`;
  const heading = "Archive File Details";
  const [error, setError] = useState("");
  const [password, setPassword] = useState(loaderPassword);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  async function submitPassword(event: FormEvent) {
    event.preventDefault();
    let form = event.target as HTMLFormElement;
    let password = (form.elements.namedItem("password-input") as HTMLInputElement).value;
    if (!password) {
      setError("Please enter a password.");
    } else {
      setLoading(true);
      try {
        await apiSetArchiveFilePassword(hash, password);
        setPassword(password);
      } catch {
        setError("Invalid password");
      } finally {
        setLoading(false);
      }
    }
  }

  return (
    <PageSkeleton name="archives" title={title} heading={heading}>
      <small>
        <KemonoLink url="#" onClick={() => navigate(-1)}>« Go Back</KemonoLink>{" | "}
        <KemonoLink url={`/search_hash?hash=${hash}`}>Find Posts</KemonoLink>
      </small>
      <article className={styles.article}>
        <header>
          <h3>Hash: {hash}</h3>
          {password ? (
            <h5>Password: <code className={styles.code}>{password}</code></h5>
          ) : (password === "") ? (
            <>
              <span>Password required but not found.</span>
              <form onSubmit={submitPassword}>
                <input type="text" name="password-input" placeholder="Password" autoComplete="off" />
                <input type="submit" value={loading ? "Testing..." : "Try Password"} disabled={loading}></input>
                <span className={styles.error} hidden={!error}>{error}</span>
              </form>
            </>
          ) : (
            <>Password not required.</>
          )}
        </header>

        <section>
          <h5>Files</h5>
          {file_list.length === 0 ? (
            <>Archive is empty or missing password.</>
          ) : (
            <ul>
              {file_list.map((filename, index) => (
                <FileItem
                  key={`${filename}-${index}`}
                  name={filename}
                  archiveHash={hash}
                  archiveExtension={ext}
                />
              ))}
            </ul>
          )}
        </section>
      </article>
    </PageSkeleton>
  );
}

export async function loader({ params }: LoaderFunctionArgs): Promise<IProps> {
  const fileHash = params.file_hash?.trim();

  if (!fileHash) {
    throw new Error("File hash is required.");
  }

  const archive = await apiFetchArchiveFile(fileHash);

  if (!archive) {
    throw new Error("Archive file not found.");
  }

  return {
    archive,
  };
}
/*
export async function action({ params, request }: ActionFunctionArgs) {
  try {
    const method = request.method;

    switch (method) {
      case "PATCH": {
        const fileHash = params.file_hash?.trim();

        if (!fileHash) {
          throw new Error("File hash is required.");
        }

        const data = await request.formData();
        const password = data.get("password") as string | null;

        if (!password) {
          throw new Error("Password is required.");
        }

        await apiSetArchiveFilePassword(fileHash, password);
        const url = String(createFilePageURL(fileHash));

        return redirect(url);
      }

      default: {
        throw new Error(`Unknown method "${method}".`);
      }
    }
  } catch (error) {
    return error;
  }
}
*/
