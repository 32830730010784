import { IPagination } from "./types";

export const PAGINATION_LIMIT = 50;

export function parseOffset(offset: string | number, limit = PAGINATION_LIMIT) {
  const parsedOffset =
    typeof offset === "number" ? offset : parseInt(offset.trim(), 10);

  if (parsedOffset % limit !== 0) {
    throw new Error(`Offset ${offset} is not a multiple of ${limit}.`);
  }

  return parsedOffset;
}

export function parsePageNumber(page: string | number | undefined): number {
  if (page === undefined) {
    throw new Error("Page number is required.");
  }

  const parsedPage =
    typeof page === "number" ? page : Number.parseInt(page.trim(), 10);

  if (parsedPage < 1) {
    throw new Error("Page number must be positive.");
  }

  return parsedPage;
}

export function createPagination(
  totalCount: number,
  currentPage?: number
): IPagination {
  const limit = PAGINATION_LIMIT;
  const totalPages = Math.ceil(totalCount / limit);
  const current = currentPage ?? totalPages;
  const currentMin = Math.min((current - 1) * limit + 1, totalCount);
  const currentMax = Math.min(currentMin - 1 + limit, totalCount);

  const pagination: IPagination = {
    total_count: totalCount,
    limit,
    total_pages: totalPages,
    current_page: current,
    current_min: currentMin,
    current_max: currentMax,
  };

  return pagination;
}
