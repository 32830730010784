import clsx from "clsx";
import { IRequiredSome } from "#lib/types";
import { IChildlessBlockProps, createBlockComponent } from "#components/meta";

export interface IInputBaseProps
  extends IRequiredSome<IChildlessBlockProps<"input">, "id" | "name"> {}

export const InputBase = createBlockComponent(undefined, Component);

function Component({ className, ...props }: IInputBaseProps) {
  return <input className={clsx("form__input", className)} {...props} />;
}
