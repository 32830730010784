import { LoaderFunctionArgs, useLoaderData } from "react-router";
import { parseOffset } from "#lib/pagination";
import { fetchDiscordChannel, fetchDiscordServer } from "#api/profiles/discord";
import { PageSkeleton } from "#components/pages";
import {
  DiscordMessages,
  DiscordServer,
  IDiscordChannelMessage,
} from "#entities/posts";

import * as styles from "./discord-channel.module.scss";

interface IProps {
  serverID: string;
  channelID: string;
  channels: { id: string; name: string }[];
  messages: IDiscordChannelMessage[];
  offset?: number;
}

export function DiscordChannelPage() {
  const { serverID, channelID, channels, messages, offset } =
    useLoaderData() as IProps;
  const title = "Discord channel";
  const heading = "Discord Channel";

  return (
    <PageSkeleton name={""} title={title} heading={heading}>
      <div className={styles.main}>
        <DiscordServer serverID={serverID} channels={channels} />
        <DiscordMessages
          serverID={serverID}
          channelID={channelID}
          messages={messages}
          offset={offset}
        />
      </div>
    </PageSkeleton>
  );
}

export async function loader({
  params,
  request,
}: LoaderFunctionArgs): Promise<IProps> {
  const searchParams = new URL(request.url).searchParams;

  const serverID = params.server_id?.trim();
  if (!serverID) {
    throw new Error("Server ID is required.");
  }

  const channelID = params.channel_id?.trim();
  if (!channelID) {
    throw new Error("Channel ID is required.");
  }

  let offset: number | undefined;
  {
    const inputOffset = searchParams.get("o");

    if (inputOffset) {
      offset = parseOffset(inputOffset, 150);
    }
  }

  const channels = await fetchDiscordServer(serverID);
  const messages = await fetchDiscordChannel(channelID, offset);

  return {
    serverID,
    channelID,
    channels,
    messages,
    offset,
  };
}
