import { createBlockComponent } from "#components/meta";
import { IInputBaseProps, InputBase } from "./base";

export interface IInputNumberProps
  extends Omit<IInputBaseProps, "type" | "defaultValue" | "min" | "max"> {
  defaultValue?: number;
  min?: number;
  max?: number;
}

export const InputNumber = createBlockComponent(undefined, Component);

function Component({ ...props }: IInputNumberProps) {
  return <InputBase {...props} type="number" />;
}
