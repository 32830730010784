import { ReactNode } from "react";
import { IBlockProps, createBlockComponent } from "#components/meta";

/**
 * TODO: `onClick` required
 */
interface IProps extends IBlockProps<"button"> {
  className?: string;
  isFocusable?: boolean;
  children?: ReactNode;
}

export const Button = createBlockComponent("button", Component);

export function Component({ isFocusable = true, children, ...props }: IProps) {
  return (
    <button {...props} tabIndex={!isFocusable ? undefined : -1}>
      {children}
    </button>
  );
}
