import { LoaderFunctionArgs, useLoaderData } from "react-router";
import { createSharesPageURL } from "#lib/urls";
import { parseOffset } from "#lib/pagination";
import { fetchShares } from "#api/shares";
import { PageSkeleton } from "#components/pages";
import { Paginator } from "#components/pagination";
import { CardList, ShareCard } from "#components/cards";
import { IShare } from "#entities/files";

interface IProps {
  count: number;
  offset?: number;
  shares: IShare[];
}

export function SharesPage() {
  const { count, offset, shares } = useLoaderData() as IProps;
  const title = "Filehaus";
  const heading = "Filehaus";

  return (
    <PageSkeleton name="all-dms" title={title} heading={heading}>
      <div className="paginator" id="paginator-top">
        <Paginator
          count={count}
          offset={offset}
          constructURL={(offset) => String(createSharesPageURL(offset))}
        />
      </div>

      <CardList layout="phone">
        {count === 0 ? (
          <div className="no-results">
            <h2 className="site-section__subheading">
              Nobody here but us chickens!
            </h2>
            <p className="subtitle">There are no uploads.</p>
          </div>
        ) : (
          shares.map((share) => <ShareCard key={share.id} share={share} />)
        )}
      </CardList>

      <div className="paginator" id="paginator-bottom">
        <Paginator
          count={count}
          offset={offset}
          constructURL={(offset) => String(createSharesPageURL(offset))}
        />
      </div>
    </PageSkeleton>
  );
}

export async function loader({ request }: LoaderFunctionArgs): Promise<IProps> {
  const searchParams = new URL(request.url).searchParams;

  let offset: number | undefined = undefined;
  {
    const inputOffset = searchParams.get("o")?.trim();

    if (inputOffset) {
      offset = parseOffset(inputOffset);
    }
  }

  const { props } = await fetchShares(offset);
  const { count, shares } = props;

  return {
    offset,
    count,
    shares,
  };
}
