import { apiFetch } from "#lib/api";

interface FlagPostParams {
  service: string;
  creatorId: string;
  postId: string;
  reason: string;
}

export async function flagPost({ service, creatorId, postId, reason }: FlagPostParams): Promise<string | null> {
  let path = `/${service}/user/${creatorId}/post/${postId}/flag`;

  return await apiFetch<string | null>(path, { method: "POST", body: { reason } });
}
