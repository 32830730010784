import { apiFetch } from "#lib/api";
import { IDiscordChannelMessage } from "#entities/posts";

export async function fetchDiscordServer(serverID: string) {
  const path = `/discord/channel/lookup/${serverID}`;

  const result = await apiFetch<{ id: string; name: string }[]>(path, {
    method: "GET",
  });

  return result;
}

export async function fetchDiscordChannel(channelID: string, offset?: number) {
  const path = `/discord/channel/${channelID}`;
  const params = new URLSearchParams();

  if (offset) {
    params.set("o", String(offset));
  }

  const result = await apiFetch<IDiscordChannelMessage[]>(
    path,
    { method: "GET" },
    params
  );

  return result;
}
