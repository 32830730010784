import { createAccountsPageURL } from "#lib/urls";
import { createAccountPageLoader, PageSkeleton } from "#components/pages";
import { KemonoLink } from "#components/links";
import { Overview, OverviewHeader } from "#components/overviews";

import * as styles from "./dashboard.module.scss";

export function AdministratorDashboardPage() {
  return (
    <PageSkeleton name="admin-dashboard" title="Admin dashboard">
      <Overview className={styles.overview}>
        <OverviewHeader>
          <nav>
            <ul>
              <li>
                <KemonoLink url={String(createAccountsPageURL())}>
                  Accounts
                </KemonoLink>
              </li>
            </ul>
          </nav>
        </OverviewHeader>
      </Overview>
    </PageSkeleton>
  );
}

export const loader = createAccountPageLoader();
