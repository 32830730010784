import { apiFetch } from "#lib/api";

interface IResult {
  id: number;
  hash: string;
  mtime: string;

  ctime: string;

  mime: string;
  ext: string;
  added: string;

  size: number;
  ihash: string;

  posts: IPostResult[];
  discord_posts: IDiscordPostResult[];
}

interface IPostResult {
  file_id: number;
  id: string;
  user: string;
  service: string;
  title: string;
  substring: string;
  published: string;

  file: {
    name: string;
    path: string;
  };
  attachments: { name: string; path: string }[];
}

interface IDiscordPostResult {
  file_id: number;
  id: string;
  server: string;
  channel: string;
  substring: string;
  published: string;

  embeds: unknown[];
  mentions: unknown[];
  attachments: { name: string; path: string }[];
}

export async function fetchSearchFileByHash(fileHash: string) {
  const path = `/search_hash/${fileHash}`;

  const result = await apiFetch<IResult>(path, { method: "GET" });

  return result;
}
