import clsx from "clsx";
import { THUMBNAILS_PREPEND } from "#env/env-vars";
import { createPostURL } from "#lib/urls";
import { Timestamp } from "#components/dates";
import { KemonoLink } from "#components/links";
import { IPost } from "#entities/posts";

interface IProps {
  post: IPost;
  isFavourite?: boolean;
  isServiceIconsDisabled?: boolean;
  isFavouriteProfile?: boolean;
  showFavCount?: boolean;
}

const fileExtensions = [".gif", ".jpeg", ".jpg", ".jpe", ".png", ".webp"];
const someServices = ["fansly", "candfans", "boosty", "gumroad"];

export function PostCard({
  post,
  isServiceIconsDisabled,
  isFavourite = false,
  isFavouriteProfile,
  showFavCount,
}: IProps) {
  const {
    service,
    user: profileID,
    id,
    title,
    content,
    published,
    attachments,
    fav_count,
  } = post;
  const postLink = String(createPostURL(service, profileID, id));
  const srcNS = findNamespace(post);
  const blockClassName = clsx(
    "post-card",
    "post-card--preview",
  );
  const blockClassNameHeader = clsx(
    "post-card__header",
    (isFavourite || isFavouriteProfile) && "post-card__header--fav"
  );
  const blockClassNameFooter = clsx(
    "post-card__footer",
    (isFavourite || isFavouriteProfile) && "post-card__footer--fav"
  );
  const trimmedTitle = title?.trim();
  const parsedTitle = !trimmedTitle
    ? undefined
    : trimmedTitle.length > 50
    ? `${trimmedTitle.slice(0, 50)}...`
    : trimmedTitle;

  return (
    <article
      className={blockClassName}
      data-id={id}
      data-service={service}
      data-user={profileID}
    >
      <KemonoLink url={postLink}>
        <header className={blockClassNameHeader}>
          {parsedTitle && parsedTitle !== "DM"
            ? parsedTitle
            : !content || content?.length < 50
            ? content
            : `${content.slice(0, 50)}...`}
        </header>

        {!srcNS.src ? undefined : (
          <div className="post-card__image-container">
            <img
              className="post-card__image"
              src={`${THUMBNAILS_PREPEND}/thumbnail/data${srcNS.src}`}
            />
          </div>
        )}

        <footer className={blockClassNameFooter}>
          <div>
            <div>
              {!published ? undefined : <Timestamp time={published} />}
              <div>
                {!attachments?.length ? (
                  <>No attachments</>
                ) : (
                  <>
                    {attachments.length}{" "}
                    {attachments.length === 1 ? "attachment" : "attachments"}
                  </>
                )}

                {showFavCount && (
                  <>
                    <br />
                    {Math.floor(fav_count ?? 0)}{" "}
                    {fav_count === 1 ? "favorite" : "favorites"}
                  </>
                )}
              </div>
            </div>
            {isServiceIconsDisabled ? undefined : (
              <img src={`/static/small_icons/${service}.png`} />
            )}
          </div>
        </footer>
      </KemonoLink>
    </article>
  );
}

function findNamespace(post: IPost) {
  const srcNS: { found: boolean; src?: string } = { found: false };
  const path = post.file?.path?.toLowerCase();
  const isValidpath = path && fileExtensions.find((ext) => path.endsWith(ext));

  if (isValidpath) {
    srcNS.src = path;
  }

  if (!isValidpath && someServices.includes(post.service)) {
    const matchedFile = post.attachments.find((file) =>
      fileExtensions.find((ext) => file.path?.endsWith(ext))
    );

    srcNS.src = matchedFile?.path;
  }

  return srcNS;
}
