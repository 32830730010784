import { apiV2Fetch } from "#lib/api";

interface IBody {
  role: string;
}

export async function apiChangeTargetAccountRole(
  accountID: number,
  role: string
) {
  const pathSpec = `/account/administrator/account/{account_id}`;
  const path = `/account/administrator/account/${accountID}`;
  const body: IBody = {
    role,
  };

  const targetAccountID = await apiV2Fetch<number>(pathSpec, "PATCH", path, {
    body,
  });

  return targetAccountID;
}
