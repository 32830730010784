import { ReactNode } from "react";
import { createBlockComponent } from "#components/meta";
import { Select } from "../inputs";
import { Label } from "../label";
import { FormSection } from "./section";
import { IBaseFormSectionProps } from "./types";

interface IProps extends IBaseFormSectionProps {
  options: ReactNode;
}

export const FormSectionSelect = createBlockComponent(undefined, Component);

function Component({
  id,
  name,
  label,
  defaultValue,
  options,
  children,
  ...props
}: IProps) {
  return (
    <FormSection {...props}>
      <Label htmlFor={id}>{label}</Label>

      <Select id={id} name={name} defaultValue={defaultValue}>
        {options}
      </Select>

      {children && <div>{children}</div>}
    </FormSection>
  );
}
