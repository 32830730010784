import { apiFetch } from "#lib/api";

export interface IArchiveFile {
  password?: string;
  file: {
    hash: string;
    ext: string;
  };
  file_list: string[];
}

export async function apiFetchArchiveFile(fileHash: string) {
  const path = `/file/${fileHash}`;

  const result = await apiFetch<IArchiveFile>(path, { method: "GET" });

  return result;
}

type SetPasswordBody = Array<string>;
type SetPasswordResponse = "ok" | {
  error: string;
};

export async function apiSetArchiveFilePassword(
  archiveHash: string,
  password: string
): Promise<SetPasswordResponse> {
  const path = `/file/${archiveHash}`;
  const body: SetPasswordBody = [password];

  const result = await apiFetch<SetPasswordResponse>(path, { body, method: "PATCH" });

  return result;
}
