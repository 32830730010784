import { useLoaderData } from "react-router";
import { fetchAccountNotifications } from "#api/account";
import { INotification } from "#entities/account";
import { PageSkeleton, createAccountPageLoader } from "#components/pages";
import { NotificationItem } from "#entities/account";

interface IProps {
  notifications: INotification[];
}

export function AccountNotificationsPage() {
  const { notifications } = useLoaderData() as IProps;
  const title = "Account notificatons";
  const heading = "Notificatons";

  return (
    <PageSkeleton name="account-notifications" title={title} heading={heading}>
      <ul className="notifications__list">
        {notifications.length === 0 ? (
          <li className="notifications__item">There are no notifications.</li>
        ) : (
          notifications.map((notification) => (
            <NotificationItem
              key={notification.id}
              notification={notification}
            />
          ))
        )}
      </ul>
    </PageSkeleton>
  );
}

export const loader = createAccountPageLoader(
  async function loader(): Promise<IProps> {
    const { notifications } = await fetchAccountNotifications();

    return { notifications };
  }
);
