const storageNames = [
  "favorites",
  "logged_in",
  "role",
  "favs",
  "post_favs",
  "has_pending_review_dms",
  "last_checked_has_pending_review_dms",
  "sidebar_state",
] as const;

export interface ILocalStorageSchema {
  favs: {
    service: string;
    id: string;
  }[];
  post_favs: {
    id: string;
    service: string;
    user: string;
  }[];
}

type ILocalStorageName = (typeof storageNames)[number];

let localStorageAvailable: boolean | null = null;

function checkLocalStorageAvailability(): boolean {
  if (localStorageAvailable === null) {
    try {
      localStorage.setItem("__storage_test__", "__storage_test__");
      localStorage.removeItem("__storage_test__");
      localStorageAvailable = true;
    } catch (error) {
      localStorageAvailable = false;
    }
  }
  return localStorageAvailable;
}

export function getLocalStorageItem(name: ILocalStorageName): string | null {
  if (!checkLocalStorageAvailability()) {
    console.warn("LocalStorage is not available.");
    return null;
  }
  return localStorage.getItem(name);
}

export function setLocalStorageItem(name: ILocalStorageName, value: string): void {
  if (!checkLocalStorageAvailability()) {
    console.warn("LocalStorage is not available.");
    return;
  }
  try {
    localStorage.setItem(name, value);
  } catch (error) {
    console.error("Failed to set item in LocalStorage:", error);
  }
}

export function deleteLocalStorageItem(name: ILocalStorageName): void {
  if (!checkLocalStorageAvailability()) {
    console.warn("LocalStorage is not available.");
    return;
  }
  try {
    localStorage.removeItem(name);
  } catch (error) {
    console.error("Failed to remove item from LocalStorage:", error);
  }
}

export function isLocalStorageAvailable() {
  try {
    localStorage.setItem("__storage_test__", "__storage_test__");
    localStorage.removeItem("__storage_test__");
    return true;
  } catch (error) {
    return false;
  }
}
