import { Helmet } from "@dr.pogodin/react-helmet";
import { ICONS_PREPEND, KEMONO_SITE } from "#env/env-vars";
import { IArtistDetails } from "#entities/profiles";
import { IPageProps, PageSkeleton } from "./site";

interface IProps extends IPageProps {
  profile: IArtistDetails;
}

export function ProfilePageSkeleton({
  profile,
  children,
  title,
  ...props
}: IProps) {
  const { id, service, name } = profile;

  return (
    <PageSkeleton {...props} title={title}>
      <Helmet>
        <meta name="id" content={id} />
        <meta name="service" content={service} />
        <meta name="artist_name" content={name} />
        {/* <link rel="canonical" href="{{ g.canonical_url }}"/> */}

        <meta property="og:title" content={title} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={KEMONO_SITE} />
        <meta property="og:site_name" content={KEMONO_SITE} />
        <meta
          property="og:image"
          content={`${ICONS_PREPEND ?? KEMONO_SITE}}/icons/${service}/${id}`}
        />
        {/* <meta property="og:url" content="{{ g.canonical_url }}"/> */}
      </Helmet>
      {children}
    </PageSkeleton>
  );
}
