import clsx from "clsx";
import { IRequiredSome } from "#lib/types";
import { IBlockProps, createBlockComponent } from "#components/meta";

interface ISelectProps
  extends IRequiredSome<IBlockProps<"select">, "id" | "name"> {}
interface IOptionProps extends IBlockProps<"option"> {}

export const Select = createBlockComponent(undefined, SelectComponent);
export const Option = createBlockComponent(undefined, OptionComponent);

function SelectComponent({ className, ...props }: ISelectProps) {
  return <select className={clsx("form__select", className)} {...props} />;
}

function OptionComponent({ className, ...props }: IOptionProps) {
  return <option className={clsx("form__option", className)} {...props} />;
}
