import clsx from "clsx";
import { useEffect, useRef, useState, type ReactNode } from "react";
import { HelmetProvider } from "@dr.pogodin/react-helmet";
import { Link, Outlet, ScrollRestoration, useLocation } from "react-router";
import {
  ANNOUNCEMENT_BANNER_GLOBAL,
  ARTISTS_OR_CREATORS,
  BANNER_GLOBAL,
  DISABLE_DMS,
  DISABLE_FAQ,
  DISABLE_FILEHAUS,
  SIDEBAR_ITEMS,
} from "#env/env-vars";
import {
  createAPIDocumentationPageURL,
  createAccountFavoriteProfilesPageURL,
  createLoginPageURL,
  createLogoutPageURL,
  createRegistrationPageURL,
} from "#lib/urls";
import { fetchHasPendingDMs } from "#api/dms";
import { getLocalStorageItem, setLocalStorageItem } from "#storage/local";
import { ClientProvider } from "#hooks";
import { isRegisteredAccount } from "#entities/account";
import { NavEntry, NavItem, NavList, type INavItem } from "./sidebar";
import { GlobalFooter } from "./footer";

interface ILayoutProps {
  children: ReactNode;
}

interface IGlobalBodyProps extends ILayoutProps {
  isSidebarClosed: boolean;
  closeSidebar: (_?: any, setState?: boolean) => void;
  noAnim: boolean;
}

interface IGlobalSidebarProps {
  isSidebarClosed: boolean;
  closeSidebar: (_?: any, setState?: boolean) => void;
  noAnim: boolean;
}

interface IHeaderLinkProps {
  url: string;
  text: string;
  className?: string;
}

const SIDEBAR_MIN_WIDTH = 1020; // match to $sidebar-min-width

/**
 * TODO: Matomo integration
 */
export function Layout() {
  const location = useLocation();
  const isMobileLayout = useRef(window.innerWidth <= SIDEBAR_MIN_WIDTH);
  const [forceNoAnim, setForceNoAnim] = useState(false);
  const [isSidebarClosed, switchSidebar] = useState<boolean>(() => {
    const sidebarState = getLocalStorageItem("sidebar_state");

    // mobile devices should always have the sidebar closed due to inconvenience
    if (window.innerWidth <= SIDEBAR_MIN_WIDTH) return true;
    // keep open unless user closed it
    if (typeof sidebarState !== "string") {
      return false;
    }
    return sidebarState === "true";
  });

  // check if location changed, if so and we're on mobile, close the sidebar
  useEffect(() => {
    if (isMobileLayout.current) {
      switchSidebar(true);
    }
  }, [location.pathname]);


  useEffect(() => {
    if (!forceNoAnim) return;
    // kill transitions after first actual frame got rendered
    const animFrame = requestAnimationFrame(() => {
      setForceNoAnim(false);
    });
    return () => {
      cancelAnimationFrame(animFrame);
    };
  }, [forceNoAnim]);

  useEffect(() => {
    function onResize() {
      if (window.innerWidth > SIDEBAR_MIN_WIDTH) {
        // due to code below, check if the current sidebar state in local storage mismatches
        const sidebarState = getLocalStorageItem("sidebar_state");
        if (((typeof sidebarState !== "string") || sidebarState === "false") && isSidebarClosed) {
          // kill animations, and open sidebar
          setForceNoAnim(true);
          switchSidebar(false);
        }
        isMobileLayout.current = false;
        return;
      }
      if (isMobileLayout.current) return;
      // only trigger once
      isMobileLayout.current = true;
      // window was rezied below SIDEBAR_MIN_WIDTH, force no animation else it'll briefly appear (minor)
      // also close sidebar, mobile devices should always have the sidebar closed
      setForceNoAnim(true);
      switchSidebar(true);
    }

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [isSidebarClosed]);

  function closeSidebar(_?: unknown, setState = true) {
    // don't save state for mobile devices, as it should always be closed
    if (setState && window.innerWidth > SIDEBAR_MIN_WIDTH) {
      setLocalStorageItem("sidebar_state", !isSidebarClosed ? "true" : "false");
    }

    switchSidebar((isClosed) => !isClosed);
  }

  return (
    <ClientProvider>
      <HelmetProvider>
        <GlobalSidebar
          isSidebarClosed={isSidebarClosed}
          closeSidebar={closeSidebar}
          noAnim={forceNoAnim}
        />

        <GlobalBody
          isSidebarClosed={isSidebarClosed}
          closeSidebar={closeSidebar}
          noAnim={forceNoAnim}
        >
          <Outlet />
        </GlobalBody>

        <ScrollRestoration />
      </HelmetProvider>
    </ClientProvider>
  );
}

function GlobalSidebar({ isSidebarClosed, closeSidebar, noAnim }: IGlobalSidebarProps) {
  const navListItems: INavItem[][] = [
    [
      {
        header: true,
        text: ARTISTS_OR_CREATORS,
        icon: "/static/menu/artists.svg",
      },
      {
        text: "Search",
        link: "/artists",
        icon: "/static/menu/search.svg",
      },
      {
        text: "Recent",
        link: "/artists/updated",
        icon: "/static/menu/recent.svg",
      },
      {
        text: "Random",
        link: "/artists/random",
        icon: "/static/menu/random1.svg",
      },
    ],
    [
      { header: true, text: "Posts", icon: "/static/menu/posts.svg" },
      { text: "Search", link: "/posts", icon: "/static/menu/search.svg" },
      {
        text: "DMs",
        link: "/dms",
        icon: "/static/menu/dm.svg",
        disable: DISABLE_DMS,
      },
      {
        text: "Popular",
        link: "/posts/popular",
        icon: "/static/menu/recent.svg",
      },
      {
        text: "Hash Lookup",
        link: "/search_hash",
        icon: "/static/menu/search.svg",
      },
      { text: "Tags", link: "/posts/tags", icon: "/static/menu/tag.svg" },
      {
        text: "Random",
        link: "/posts/random",
        icon: "/static/menu/random2.svg",
      },
    ],
    [
      { header: true, text: "Importer", icon: "/static/menu/importer.svg" },
      { text: "Import", link: "/importer", icon: "/static/menu/import.svg" },
      {
        text: "FAQ",
        link: "/importer/tutorial",
        icon: "/static/menu/faq.svg",
        disable: DISABLE_FAQ,
      },
    ],
    // [
    //   { header: true, text: "Documentation" },
    //   {
    //     text: "API",
    //     link: String(createAPIDocumentationPageURL()),
    //   },
    // ],
    [
      { header: true, text: "Filehaus", disable: DISABLE_FILEHAUS },
      { text: "Recent", link: "/shares", disable: DISABLE_FILEHAUS },
    ],
  ];

  return (
    <div className={clsx(
      "global-sidebar",
      isSidebarClosed ? "retracted" : "expanded",
      {
        "disable-transitions": noAnim,
      }
    )}>
      <NavEntry className="clickable-header-entry">
        <NavItem
          link="/"
          text="Home"
          className="clickable-header home-button"
          icon="/static/menu/home.svg"
        />

        <div className="close-sidebar" onClick={closeSidebar}>
          <img src="/static/close.svg" />
        </div>
      </NavEntry>

      <NavList items={navListItems} />

      <AccountEntry />

      <NavEntry items={SIDEBAR_ITEMS} className="stuck-bottom" />
    </div>
  );
}

/**
 * TODO: a better auth state tracking
 */
function AccountEntry() {
  const location = useLocation();
  const isLoggedIn = isRegisteredAccount();
  const [isPendingDMsForReview, switchPendingDMsForReview] = useState(false);

  useEffect(() => {
    (async () => {
      const isRegistered = isRegisteredAccount();

      if (!isRegistered) {
        return;
      }

      const isPending = await fetchHasPendingDMs();
      switchPendingDMsForReview(isPending);
    })();
  }, []);

  const loggedOutEntries: INavItem[] = [
    {
      header: true,
      text: "Account",
      className: "account-header",
      icon: "/static/menu/account.svg",
    },
    {
      text: "Register",
      link: String(createRegistrationPageURL(location.pathname)),
      className: "register",
      icon: "/static/menu/register.svg",
    },
    {
      text: "Login",
      link: String(createLoginPageURL(location.pathname)),
      className: "login",
      icon: "/static/menu/login.svg",
    },
  ];
  const loggedInEntries: INavItem[] = [
    {
      header: true,
      text: "Account",
      link: "/account",
      className: "account-header",
      icon: "/static/menu/account.svg",
    },
    {
      text: "Keys",
      link: `/account/keys`,
      icon: "/static/menu/keys.svg",
    },
    {
      text: "Favorites",
      link: String(createAccountFavoriteProfilesPageURL()),
      className: "favorites",
      icon: "/static/menu/favorites.svg",
    },
    {
      text: "Review DMs",
      link: "/account/review_dms",
      className: "review_dms",
      icon: isPendingDMsForReview
        ? "/static/menu/red_dm.svg"
        : "/static/menu/dm.svg",
    },
    {
      text: "Logout",
      link: String(createLogoutPageURL()),
      className: "logout",
      icon: "/static/menu/logout.svg",
    },
  ];

  return (
    <NavEntry
      items={isLoggedIn ? loggedInEntries : loggedOutEntries}
      className="account"
    />
  );
}

function GlobalBody({
  isSidebarClosed,
  closeSidebar,
  noAnim,
  children,
}: IGlobalBodyProps) {
  const location = useLocation();
  const isLoggedIn = isRegisteredAccount();
  const backdropClassName = clsx(
    "backdrop",
    isSidebarClosed && "backdrop-hidden"
  );
  const headerClassName = clsx(
    "header",
    isSidebarClosed && "sidebar-retracted"
  );

  return (
    <div className={clsx(
      "content-wrapper",
      {
        "shifted": !isSidebarClosed,
        "disable-transitions": noAnim,
      }
    )}>
      <div className={backdropClassName} onClick={closeSidebar} />

      <div className={headerClassName}>
        <div id="burgor" onClick={closeSidebar}>
          <img src="/static/menu.svg" />
        </div>
        <HeaderLink url="/" text="Home" className="home" />
        <HeaderLink url="/artists" text={ARTISTS_OR_CREATORS} />
        <HeaderLink url="/posts" text="Posts" />
        <HeaderLink url="/importer" text="Import" className="import" />
        {isLoggedIn ? (
          <>
            <HeaderLink
              url={String(createAccountFavoriteProfilesPageURL())}
              text="Favorites"
            />
            <HeaderLink url={String(createLogoutPageURL())} text="Logout" />
          </>
        ) : (
          <>
            <HeaderLink
              url={String(createRegistrationPageURL(location.pathname))}
              text="Register"
              className="register"
            />
            <HeaderLink
              url={String(createLoginPageURL(location.pathname))}
              text="Login"
              className="login"
            />
          </>
        )}
      </div>

      {BANNER_GLOBAL && (
        <div id="ad-banner">
          <aside dangerouslySetInnerHTML={{ __html: atob(BANNER_GLOBAL) }} />
        </div>
      )}

      {ANNOUNCEMENT_BANNER_GLOBAL && (
        <div id="announcement-banner">
          <aside dangerouslySetInnerHTML={{ __html: atob(ANNOUNCEMENT_BANNER_GLOBAL) }} />
        </div>
      )}

      <main className="main" id="main">
        {children}
      </main>

      <GlobalFooter />

    </div>
  );
}

function HeaderLink({ url, text, className }: IHeaderLinkProps) {
  return (
    <Link to={url} className={clsx("header-link", className)}>
      {text}
    </Link>
  );
}
