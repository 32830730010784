import { apiFetch } from "#lib/api";
import { IPopularPostsPeriod, IPost } from "#entities/posts";

interface IResult {
  info: {
    /**
     * Datetime string.
     */
    date: string;
    /**
     * Datetime string.
     */
    min_date: string;
    /**
     * Datetime string.
     */
    max_date: string;
    /**
     * Value is a tuple of date strings.
     */
    navigation_dates: Record<IPopularPostsPeriod, [string, string, string]>;
    /**
     * Human description of range.
     */
    range_desc: string;
    scale: IPopularPostsPeriod;
  };
  props: {
    currentPage: "popular_posts";
    /**
     * Date string.
     */
    today: string;
    /**
     * Date string.
     */
    earliest_date_for_popular: string;
    limit: number;
    count: number;
  };
  results: IPost[];
  base: {};
  result_previews: (
    | { type: "thumbnail"; server: string; name: string; path: string }
    | { type: "embed"; url: string; subject: string; description: string }
  )[];
  result_attachments: { server: string; name: string; path: string }[];
  result_is_image: boolean;
}

export async function fetchPopularPosts(
  date?: string,
  scale?: IPopularPostsPeriod,
  offset?: number
) {
  const path = `/posts/popular`;
  const params = new URLSearchParams();

  if (date && scale !== "recent") {
    params.set("date", date);
  }

  if (scale) {
    params.set("period", scale);
  }

  if (offset) {
    params.set("o", String(offset));
  }

  const result = await apiFetch<IResult>(path, { method: "GET" }, params);

  return result;
}
