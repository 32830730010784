import { ReactNode } from "react";
import clsx from "clsx";
import { IRequiredSome } from "#lib/types";
import { createBlockComponent, type IBlockProps } from "#components/meta";

interface IProps extends IRequiredSome<IBlockProps<"label">, "htmlFor"> {
  semicolon?: ReactNode;
}

export const Label = createBlockComponent(undefined, Component);

function Component({ className, semicolon, children, ...props }: IProps) {
  return (
    <label className={clsx("form__label", className)} {...props}>
      {children}
      {semicolon === null ? undefined : semicolon ?? ":"}
    </label>
  );
}
