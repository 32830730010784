import { apiV2Fetch } from "#lib/api";
import { IAccount } from "#entities/account";

export async function apiFetchAccount(accountID: number) {
  const pathSpec = `/account/administrator/account/{account_id}`;
  const path = `/account/administrator/account/${accountID}`;

  const result = await apiV2Fetch<IAccount>(pathSpec, "GET", path);

  return result;
}
