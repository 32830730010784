import { getLocalStorageItem } from "#storage/local";

export const accountRoles = ["consumer", "moderator", "administrator"] as const;
export const visibleRoles = [
  "consumer",
  "moderator",
] as const satisfies Exclude<IAccountRole, "administrator">[];

export type IAccountRole = (typeof accountRoles)[number];

export function isValidAccountRole(input: unknown): input is IAccountRole {
  return accountRoles.includes(input as IAccountRole);
}

export function ensureAccountRole(
  input: unknown
): asserts input is IAccountRole {
  if (!isValidAccountRole(input)) {
    throw new Error(`Invalid account role.`);
  }
}

export async function getAccountRole(): Promise<IAccountRole> {
  const localRole = getLocalStorageItem("role");

  ensureAccountRole(localRole);

  return localRole;
}
