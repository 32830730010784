import { LoaderFunctionArgs, useLoaderData } from "react-router";
import { createDMsPageURL } from "#lib/urls";
import { parseOffset } from "#lib/pagination";
import { fetchDMs } from "#api/dms";
import { PageSkeleton } from "#components/pages";
import { HeaderAd, SliderAd } from "#components/advs";
import { Paginator } from "#components/pagination";
import { CardList, DMCard } from "#components/cards";
import { ButtonSubmit, FormRouter } from "#components/forms";
import { IApprovedDM } from "#entities/dms";
import { useRef, useState } from "react";

interface IProps {
  query?: string;
  count: number;
  offset?: number;
  dms: IApprovedDM[];
}

export function DMsPage() {
  const { query, count, dms, offset } = useLoaderData() as IProps;
  const [isLoading, setIsLoading] = useState(false);
  const title = "DMs";
  const heading = "DMs";

  return (
    <PageSkeleton name="all-dms" title={title} heading={heading}>
      <SliderAd />
      <HeaderAd />

      <div className="paginator" id="paginator-top">
        <SearchForm
          query={query}
          onLoadingChange={(loading) => setIsLoading(loading)}
        />
        <Paginator
          count={count}
          offset={offset}
          constructURL={(offset) => String(createDMsPageURL(offset, query))}
        />
      </div>

      <CardList layout="phone" className={isLoading ? "card-list--loading" : ""}>
        {count === 0 ? (
          <div className="no-results">
            <h2 className="site-section__subheading">
              Nobody here but us chickens!
            </h2>
            <p className="subtitle">There are no DMs.</p>
          </div>
        ) : (
          dms.map((dm) => (
            <DMCard key={dm.hash} dm={dm} artist={dm.artist} isGlobal />
          ))
        )}
      </CardList>

      <div className="paginator" id="paginator-bottom">
        <Paginator
          count={count}
          offset={offset}
          constructURL={(offset) => String(createDMsPageURL(offset, query))}
        />
      </div>
    </PageSkeleton>
  );
}

interface ISearchFormProps
  extends Pick<IProps, "query"> {
  onLoadingChange: (loading: boolean) => void;
}

function SearchForm({ query, onLoadingChange }: ISearchFormProps) {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);

    const target = e.currentTarget as HTMLInputElement;

    onLoadingChange(true);

    timeoutRef.current = setTimeout(() => {
      if (target.form) target.form.requestSubmit();
      onLoadingChange(false);
    }, 1000);
  };

  return (
    <FormRouter
      id="search-form"
      className="search-form"
      method="GET"
      autoComplete="off"
      noValidate={true}
      acceptCharset="UTF-8"
      statusSection={null}
      submitButton={undefined}
      style={{ maxWidth: "fit-content" }}
    >
      {(state) => (
        <>
          <div className="wrapper">
            <input
              type="text"
              name="q"
              id="q"
              autoComplete="off"
              defaultValue={query}
              minLength={3}
              placeholder="Search..."
              onChange={onInputChange}
            />
            <ButtonSubmit
              disabled={state === "loading" || state === "submitting"}
              className="search-button"
              onClick={() => {
                if (timeoutRef.current) clearTimeout(timeoutRef.current);
                onLoadingChange(false);
              }}
            >
              <img src='/static/menu/search.svg' />
            </ButtonSubmit>
          </div>
        </>
      )}
    </FormRouter>
  );
}

export async function loader({ request }: LoaderFunctionArgs): Promise<IProps> {
  const searchParams = new URL(request.url).searchParams;

  let offset: number | undefined = undefined;
  {
    const inputOffset = searchParams.get("o")?.trim();
    if (inputOffset) {
      offset = parseOffset(inputOffset);
    }
  }

  const query = searchParams.get("q")?.trim();

  const { props } = await fetchDMs(offset, query);
  const { count, dms } = props;

  return {
    count,
    offset,
    query,
    dms,
  };
}
