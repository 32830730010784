import clsx from "clsx";
import { ReactNode } from "react";
import { PAGINATION_LIMIT } from "#lib/pagination";
import { KemonoLink } from "#components/links";

interface IProps {
  count: number;
  true_count?: number;
  offset?: number;
  constructURL: (offset: number) => string;
}

interface IPaginatorButtonProps {
  href: string;
  className?: string;
  children: ReactNode;
}

export function Paginator({
  count,
  true_count,
  offset = 0,
  constructURL,
}: IProps) {
  // there are less than 50 items, so we don't need to show pagination
  if (count < PAGINATION_LIMIT) return null;

  const limit = PAGINATION_LIMIT;
  const skip = offset;
  // is for displaying Showing X - Y of Z, where Y is the current ceiling of range
  const currentCeilingOfRange = skip + limit < count ? skip + limit : count;
  // number of buttons shown before and after the current page number
  const buttonsPerSide = 5;
  // 5 buttons before, 5 buttons after, 1 current page button
  const totalButtonCount = buttonsPerSide * 2 + 1;
  // number of buttons that can be hidden for mobile view
  const optionalButtons = buttonsPerSide - 2;
  // number of buttons that are always shown no matter what
  const mandatoryButtons = buttonsPerSide - optionalButtons;
  const currentPageNumber = Math.ceil((skip + limit) / limit);
  const totalPages = Math.ceil(count / limit);

  // left most button is base page number
  // we show 5 buttons before and 5 after the current page number!
  // we move the buttons around if they don't fit in left or right side of the current page number
  // but the total number of buttons is always the same, aka 10 buttons
  const basePageNumber = Math.max(
    totalPages < buttonsPerSide ? 1 // less pages than buttons, start at page 1
      : currentPageNumber < buttonsPerSide ? 1 // current page number is less than num of buttons to the left, start at page 1
        : (totalPages - currentPageNumber) < buttonsPerSide // do we have more buttons than pages after the current page number? (nearing the end of the list)
          ? currentPageNumber - buttonsPerSide - (buttonsPerSide - (totalPages - currentPageNumber)) // show 5 buttons, + how many are missing after current page
          : currentPageNumber - buttonsPerSide, // show 5 buttons before the current page number
    1
  );

  // calculate which base page number starts being optional (usually current page number - 2)
  const optionalBeforeButtons =
    currentPageNumber -
    mandatoryButtons - // anything before curr page - mandatory buttons is optional
    (totalPages - currentPageNumber < mandatoryButtons // however, we have to check for if we are nearing the end of num of pages, example page 13 of 14
      ? mandatoryButtons - (totalPages - currentPageNumber) // since we are nearing the end, we will subtract how many mandatory buttons are now missing on the right side
      : 0);

  // calculate which base page number starts being optional (usually current page number + 2, an inverted version of the above)
  const optionalAfterButtons =
    currentPageNumber +
    mandatoryButtons +
    (currentPageNumber - basePageNumber < mandatoryButtons
      ? mandatoryButtons - (currentPageNumber - basePageNumber)
      : 0);

  return (
    <>
      <small>
        Showing {skip + 1} - {currentCeilingOfRange} of {true_count || count}
      </small>

      <menu>
        {
          totalPages > totalButtonCount ?
            <PaginatorButton
              className={clsx({ "pagination-button-disabled": currentPageNumber === 1 })}
              href={constructURL(0)}
            >
              {"<<"}
            </PaginatorButton>
            : null
        }
        <PaginatorButton
          className={clsx({ "pagination-button-disabled": currentPageNumber === 1 })}
          href={constructURL((currentPageNumber - 2) * limit)}
        >
          {"<"}
        </PaginatorButton>
        {
          Array.from({ length: buttonsPerSide * 2 + 1 }, (_, index) => {
            const pageNum = index + basePageNumber;
            if (pageNum > totalPages) return null; // don't show more than total pages

            return (
              <PaginatorButton
                key={index}
                href={constructURL((pageNum - 1) * limit)}
                className={clsx({
                  "pagination-button-disabled": pageNum === currentPageNumber,
                  "pagination-button-current": pageNum === currentPageNumber,
                  "pagination-button-after-current": pageNum === currentPageNumber + 1,
                  "pagination-button-optional": pageNum < optionalBeforeButtons || pageNum > optionalAfterButtons
                })}
              >
                {pageNum}
              </PaginatorButton>
            );
          })
        }
        <PaginatorButton
          className={clsx({
            "pagination-button-disabled": currentPageNumber === totalPages,
            "pagination-button-after-current": currentPageNumber === totalPages
          })}
          href={constructURL(currentPageNumber * limit)}
        >
          {">"}
        </PaginatorButton>
        {
          totalPages > totalButtonCount ?
            <PaginatorButton
              className={clsx({ "pagination-button-disabled": currentPageNumber === totalPages })}
              href={constructURL((totalPages - 1) * limit)}
            >
              {">>"}
            </PaginatorButton>
            : null
        }
      </menu>
    </>
  );
}

function PaginatorButton({ href, className, children }: IPaginatorButtonProps) {
  return href ? (
    <KemonoLink url={href} className={className}>
      <b>{children}</b>
    </KemonoLink>
  ) : (
    <li className={className}>
      <b>{children}</b>
    </li>
  );
}
