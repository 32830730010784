import { apiFetch } from "#lib/api";
import { ITag } from "#entities/tags"

interface IResult {
  props: { currentPage: "tags" }
  tags: ITag[]
}

export async function fetchTags() {
  const path = "/posts/tags"
  const result = await apiFetch<IResult>(path, { method: "GET" })

  return result
}
