import { getAccountRole, isRegisteredAccount } from "#entities/account";
import {
  LoaderFunction,
  LoaderFunctionArgs,
  ActionFunction,
} from "react-router";

export function createAccountPageLoader(
  loader?: LoaderFunction
): LoaderFunction {
  return async (args: LoaderFunctionArgs) => {
    const isRegistered = isRegisteredAccount();

    if (!isRegistered) {
      throw new Error("You must be registered to access this page.");
    }

    if (!loader) {
      return null;
    }

    return await loader(args);
  };
}

export async function validateAccountPageLoader(
  ...args: Parameters<LoaderFunction>
): Promise<void> {
  const isRegistered = isRegisteredAccount();

  if (!isRegistered) {
    throw new Error("You must be registered to access this page.");
  }
}

export async function validateAccountPageAction(
  ...args: Parameters<ActionFunction>
): Promise<void> {
  const isRegistered = isRegisteredAccount();

  if (!isRegistered) {
    throw new Error("You must be registered to access this page.");
  }
}

export async function validateAdministratorPageLoader(
  ...args: Parameters<LoaderFunction>
): Promise<void> {
  await validateAccountPageLoader(...args);

  const role = await getAccountRole();

  if (role !== "administrator") {
    throw new Error("Account is not administrator.");
  }
}

export async function validateAdministratorPageAction(
  ...args: Parameters<ActionFunction>
): Promise<void> {
  await validateAccountPageAction(...args);

  const role = await getAccountRole();

  if (role !== "administrator") {
    throw new Error("Account is not administrator.");
  }
}
