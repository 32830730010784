import { Timestamp } from "#components/dates";
import { FormRouter } from "#components/forms";
import {
  FormSectionRadio,
  FormSectionRadioGroup,
} from "#components/forms/sections";
import { DescriptionList, DescriptionSection } from "#components/lists";
import { createBlockComponent } from "#components/meta";
import {
  Overview,
  OverviewHeader,
  OverviewBody,
  IOverviewProps,
  OverviewFooter,
} from "#components/overviews";
import { IAccount } from "#entities/account";

interface IProps extends IOverviewProps {
  account: IAccount;
}

export const AccountOverview = createBlockComponent(undefined, Component);

function Component({ account, ...props }: IProps) {
  const { id, username, role, created_at } = account;
  const formID = `update-account-${id}`;

  return (
    <Overview {...props}>
      <OverviewHeader>
        {/* TODO: replace with a heading component */}
        <h2>{username}</h2>
        <p>{id}</p>
      </OverviewHeader>

      <OverviewBody>
        <DescriptionList>
          <DescriptionSection dKey="Role" dValue={role} isHorizontal />
          <DescriptionSection
            dKey="Joined"
            dValue={<Timestamp time={created_at} />}
          />
        </DescriptionList>
      </OverviewBody>

      <OverviewFooter>
        {/* TODO: rewrite with `useFetcher` */}
        <FormRouter
          action={`/account/administrator/account/${id}`}
          method="PATCH"
          submitButton={() => "Submit changes"}
        >
          <FormSectionRadioGroup
            id={formID}
            name="role"
            legend="Role"
            radios={(id, name) => (
              <>
                <FormSectionRadio
                  id={`${id}-consumer`}
                  name={name}
                  label={<>Consumer</>}
                  defaultChecked={role === "consumer"}
                  defaultValue="consumer"
                />
                <FormSectionRadio
                  id={`${id}-moderator`}
                  name={name}
                  label={<>Moderator</>}
                  defaultChecked={role === "moderator"}
                  defaultValue="moderator"
                />
              </>
            )}
          ></FormSectionRadioGroup>
        </FormRouter>
      </OverviewFooter>
    </Overview>
  );
}
