import { apiV2Fetch } from "#lib/api";
import { IAccount } from "#entities/account";

export async function apiCountAccounts(name?: string, role?: string) {
  const pathSpec = `/account/administrator/accounts`;
  const path = `/account/administrator/accounts`;
  const searchParams = new URLSearchParams();

  if (name) {
    searchParams.set("name", name);
  }

  if (role) {
    searchParams.set("role", role);
  }

  const result = await apiV2Fetch<number>(pathSpec, "GET", path, {
    searchParams,
  });

  return result;
}

export async function apiFetchAccounts(
  page: number,
  name?: string,
  role?: string
) {
  const pathSpec = `/account/administrator/accounts/{page}`;
  const path = `/account/administrator/accounts/${page}`;
  const searchParams = new URLSearchParams();

  if (name) {
    searchParams.set("name", name);
  }

  if (role) {
    searchParams.set("role", role);
  }

  const result = await apiV2Fetch<IAccount[]>(pathSpec, "GET", path, {
    searchParams,
  });

  return result;
}
