import { createBlockComponent } from "#components/meta";
import { IInputBaseProps, InputBase } from "./base";

export interface IInputRadioProps extends Omit<IInputBaseProps, "type"> {}

export const InputRadio = createBlockComponent(undefined, Component);

function Component({ ...props }: IInputRadioProps) {
  return <InputBase {...props} type="radio" />;
}
