import { createBlockComponent } from "#components/meta";
import { InputRadio } from "../inputs";
import { Label } from "../label";
import { FormSection } from "./section";
import { IBaseFormSectionProps } from "./types";

import * as styles from "./radio.module.scss";

interface IProps extends Omit<IBaseFormSectionProps, "children"> {}

export const FormSectionRadio = createBlockComponent(styles, Component);

function Component({
  id,
  name,
  label,
  required,
  defaultValue,
  defaultChecked,
  ...props
}: IProps) {
  return (
    <FormSection {...props}>
      <InputRadio
        id={id}
        className={styles.input}
        name={name}
        required={required}
        defaultValue={defaultValue}
        defaultChecked={defaultChecked}
      />
      <Label className={styles.label} htmlFor={id} semicolon={null}>
        {label}
      </Label>
    </FormSection>
  );
}
