import clsx from "clsx";
import { createProfilePageURL } from "#lib/urls";
import { IArtist } from "#entities/profiles";
import { IApprovedDM } from "#entities/dms";
import { paysites } from "#entities/paysites";
import { FancyLink } from "../links";

import * as styles from "./dm.module.scss"

interface IProps {
  dm: IApprovedDM;
  isPrivate?: boolean;
  isGlobal?: boolean;
  artist?: IArtist;
  className?: string;
}

export function DMCard({
  dm,
  isGlobal = false,
  isPrivate = false,
  artist,
  className,
}: IProps) {
  const { service, user } = dm;
  const paysite = paysites[service];
  const profilePageURL = String(
    createProfilePageURL({ service, profileID: user })
  );
  const remoteProfilePageURL = paysite.user.profile(artist?.id ?? user);

  return (
    <article
      className={clsx("dm-card", className)}
      data-id={!isPrivate ? undefined : dm.hash}
    >
      {!isGlobal ? undefined : (
        <header className="dm-card-header">
          <FancyLink url={profilePageURL} className="dms__user-link">
            <span className="dm-card__user">{artist?.name ?? user}</span>
          </FancyLink>
          <FancyLink
            url={remoteProfilePageURL}
            className="dms__remote-user-link"
            isNoop
          >
            <span className="dm-card__service">({paysite.title})</span>
          </FancyLink>
        </header>
      )}

      {!isPrivate ? undefined : (
        <header className="dm-card-header">
          <FancyLink url={profilePageURL} className="dms__user-link">
            <span className="dm-card__user">{artist?.name ?? user}</span>
          </FancyLink>
          <FancyLink
            url={remoteProfilePageURL}
            className="dms__remote-user-link"
            isNoop
          >
            <span className="dm-card__service">({paysite.title})</span>
          </FancyLink>
        </header>
      )}

      <section className="dm-card__body" tabIndex={0}>
        <div className="dm-card__content">
          <pre className={styles.content}>{dm.content}</pre>
        </div>
      </section>

      <footer className="dm-card__footer">
        {dm.published ? (
          <div className="dm-card__added">
            Published: {dm.published.slice(0, 7)}
          </div>
        ) : /* this is to detect if its not DM */ dm.user_id ? (
          <div className="dm-card__added">Added: {dm.added.slice(0, 7)}</div>
        ) : (
          <div className="dm-card__added">Added: {dm.added}</div>
        )}
      </footer>
    </article>
  );
}
