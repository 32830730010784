import { LoaderFunctionArgs, useLoaderData } from "react-router";
import { createFileURL } from "#lib/urls";
import { fetchShare } from "#api/shares";
import { PageSkeleton } from "#components/pages";
import { IShare, IShareFile } from "#entities/files";

interface IProps {
  share: IShare;
  files: IShareFile[];
}

export function SharePage() {
  const { share, files } = useLoaderData() as IProps;
  const title = `Share "${share.name}"`;
  const heading = `Share "${share.name}"`;

  return (
    <PageSkeleton name="upload" title={title} heading={heading}>
      <div className="upload-view">
        <pre>{share.description}</pre>

        {files.map((file) => (
          <li key={file.hash} className="post__attachment">
            <a
              className="post__attachment-link"
              href={
                file.hash
                  ? String(createFileURL(file.hash, file.ext))
                  : file.upload_url
              }
            >
              Download {file.filename}
            </a>
          </li>
        ))}
      </div>
    </PageSkeleton>
  );
}

export async function loader({ params }: LoaderFunctionArgs): Promise<IProps> {
  const shareID = params.share_id?.trim();

  if (!shareID) {
    throw new Error("Share ID is required.");
  }

  const { share, share_files } = await fetchShare(shareID);

  return {
    share,
    files: share_files,
  };
}
