import { apiFetch } from "#lib/api";
import { IArtistDetails } from "#entities/profiles";

export async function fetchArtistProfile(
  service: string,
  artistID: string
): Promise<IArtistDetails> {
  const path = `/${service}/user/${artistID}/profile`;
  const ifModifiedDate = new Date();

  ifModifiedDate.setFullYear(ifModifiedDate.getFullYear() - 1);

  const headers = new Headers([
    ["If-Modified-Since", ifModifiedDate.toUTCString()],
  ]);
  const result = await apiFetch<IArtistDetails>(path, {
    method: "GET",
    headers,
  });

  return result;
}
