import { apiFetch } from "#lib/api";
import { IArtist } from "#entities/profiles";
import { IShare } from "#entities/files";

interface IResult {
  results: IShare[];
  base: Record<string, unknown>;
  props: {
    display_data: {
      service: string;
      href: string;
    };
    service: string;
    artist: IArtist;
    id: string;
    dm_count: number;
    share_count: number;
    has_links: "✔️" | "0";
  };
}

export async function fetchProfileShares(
  service: string,
  profileID: string,
  offset?: number
) {
  const path = `/${service}/user/${profileID}/shares`;
  const params = new URLSearchParams();

  if (offset) {
    params.set("o", String(offset));
  }

  const result = await apiFetch<IResult>(path, { method: "GET" }, params);

  return result;
}
