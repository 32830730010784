// TODO: https://github.com/Daninet/hash-wasm probably
// since this one wasn't updated in 3 years
import sha256 from "sha256-wasm";
import {
  createDiscordChannelPageURL,
} from "#lib/urls";
import { fetchSearchFileByHash } from "#api/files";
import { PageSkeleton } from "#components/pages";
import { CardList, PostCard } from "#components/cards";
import { KemonoLink } from "#components/links";
import { MouseEvent, useEffect, useState } from "react";
import { LoadingIcon } from "#components/loading";
import { useSearchParams } from "react-router";

import * as styles from "./search_hash.module.scss";

export function SearchFilesPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const initialHash = searchParams.get("hash")?.toLowerCase();
  const title = "Search files";
  const heading = "Search Files";
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [files, setFiles] = useState<FileList | null>(null);
  const [hash, setHash] = useState(initialHash ?? "");
  const [result, setResult] = useState<Awaited<ReturnType<typeof fetchSearchFileByHash>> | null>(null);

  function setHashParam(hash: string) {
    setSearchParams(params => {
      params.set("hash", hash);
      return params;
    }, { replace: true });
  }

  async function lookup(hash: string) {
    setLoading(true);

    try {
      if (files?.length) {
        let fileHash = await getFileHash(files[0]);
        setResult(await fetchSearchFileByHash(fileHash));
        setHash(fileHash);
        setHashParam(fileHash);
      } else {
        if (hash.match(/[a-f0-9]{64}/)) {
          setResult(await fetchSearchFileByHash(hash));
          setHash(hash);
          setHashParam(hash);
        } else {
          setError("Invalid hash!");
        }
      }
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (initialHash) {
      lookup(initialHash);
    }
  }, []);

  async function submitClicked(event: MouseEvent) {
    event.preventDefault();
    await lookup(hash);
  }

  return (
    <PageSkeleton name="file-hash-search" title={title} heading={heading}>
      <form className={styles.searchForm}>
        <div className="form__section">
          <label htmlFor="file">File:</label>
          <input id="file" type="file" name="file" onChange={e => setFiles(e.target.files)} />
        </div>

        <div className="form__section" style={{marginTop: "-1em"}}>
          <span>
            <br />
            —or—
            <br />
          </span>
        </div>

        <div className="form__section">
          <label htmlFor="hash">SHA256 hash:</label>
          <input id="hash" type="text" name="hash" onChange={e => setHash(e.target.value.toLowerCase())} value={hash} />
        </div>

        <div className={styles.error} style={{display: error ? "block" : "none"}}>
          {error}
        </div>

        <button className="button" onClick={submitClicked} disabled={!(files?.length || hash) || loading}>Submit</button>
      </form>

      {loading ? <LoadingIcon /> : result?.posts?.length ? (
        <CardList>
          {result.posts.map((post, index) => (
            <PostCard
              key={index}
              // @ts-expect-error custom endpoint meme type
              post={post}
            />
          ))}
        </CardList>
      ) : result?.discord_posts?.length ? (
        <>
          <h2>Discord</h2>
          {result.discord_posts.map((post, index) => (
            <p key={index}>
              <KemonoLink
                url={String(
                  createDiscordChannelPageURL(post.server, post.channel)
                )}
              >
                Server {post.server} channel {post.channel}
              </KemonoLink>
            </p>
          ))}
        </>
      ) : (
        <div className="no-results">
          <h2 className="site-section__subheading">
            Nobody here but us chickens!
          </h2>
          <p className="subtitle">There are no posts for your query.</p>
        </div>
      )}
    </PageSkeleton>
  );
}

async function getFileHash(file: File) {
  const fileSize = file.size;
  const chunkSize = 1024 * 1024; // 1Mi
  let offset = 0;
  let hash = new sha256();

  while (offset < fileSize) {
    const arr = new Uint8Array(
      await file.slice(offset, chunkSize + offset).arrayBuffer()
    );
    hash.update(arr);
    offset += chunkSize;
  }

  return hash.digest("hex");
}
