import { createBlockComponent } from "#components/meta";
import { InputNumber, type IInputNumberProps } from "./number";

export interface IInputIntegerProps extends Omit<IInputNumberProps, "step"> {}

export const InputInteger = createBlockComponent(undefined, Component);

function Component({ ...props }: IInputNumberProps) {
  return <InputNumber {...props} step={1} />;
}
