import { LoaderFunctionArgs, useLoaderData } from "react-router";
import { fetchDiscordServer } from "#api/profiles/discord";
import { PageSkeleton } from "#components/pages";
import { DiscordServer } from "#entities/posts";

import * as styles from "./discord.module.scss";

interface IProps {
  serverID: string;
  channels: { id: string; name: string }[];
}

export function DiscordServerPage() {
  const { serverID, channels } = useLoaderData() as IProps;
  const title = "Discord server";
  const heading = "Discord Server";

  return (
    <PageSkeleton name={""} title={title} heading={heading}>
      <div className={styles.main}>
        <DiscordServer serverID={serverID} channels={channels} />
      </div>
    </PageSkeleton>
  );
}

export async function loader({ params }: LoaderFunctionArgs): Promise<IProps> {
  const serverID = params.server_id?.trim();
  if (!serverID) {
    throw new Error("Server ID is required.");
  }

  const channels = await fetchDiscordServer(serverID);

  return {
    serverID,
    channels,
  };
}
