import { createBlockComponent } from "#components/meta";
import { IInputBaseProps, InputBase } from "./base";

export interface IInputTextProps extends Omit<IInputBaseProps, "type"> {}

export const InputText = createBlockComponent(undefined, Component);

function Component({ ...props }: IInputTextProps) {
  return <InputBase {...props} type="text" />;
}
