import { LegacyRef, forwardRef } from "react";
import { IPagination } from "#lib/pagination";
import { IBlockProps, createBlockComponent } from "#components/meta";

import * as styles from "./pagination-info.module.scss";

interface IPaginationInfoProps extends IBlockProps<"div"> {
  pagination: IPagination;
}

export const PaginationInfo = forwardRef<HTMLDivElement, IPaginationInfoProps>(
  createBlockComponent(styles, Component)
);

export function Component(
  { pagination, ...props }: IPaginationInfoProps,
  ref: LegacyRef<HTMLDivElement>
) {
  const { total_count, current_min, current_max } = pagination;

  return (
    <div ref={ref} {...props}>
      Showing elements from {current_min} to {current_max} out of {total_count}.
    </div>
  );
}
