import { apiFetch } from "#lib/api";

interface IBody {
  "current-password": string;
  "new-password": string;
  "new-password-confirmation": string;
}

export async function fetchAccountChangePassword(
  currentPassword: string,
  newPassword: string,
  newPasswordConfirmation: string
) {
  const path = `/account/change_password`;
  const body: IBody = {
    "current-password": currentPassword,
    "new-password": newPassword,
    "new-password-confirmation": newPasswordConfirmation,
  };

  const result = await apiFetch<true>(path, { method: "POST", body });

  return result;
}
