import { createBlockComponent } from "#components/meta";
import { InputInteger, type IInputIntegerProps } from "../inputs";
import { Label } from "../label";
import { FormSection } from "./section";
import { IBaseFormSectionProps } from "./types";

interface IProps
  extends Omit<IBaseFormSectionProps, "defaultValue" | "defaultChecked">,
    Pick<IInputIntegerProps, "defaultValue" | "min" | "max"> {}

export const FormSectionInteger = createBlockComponent(undefined, Component);

function Component({
  id,
  name,
  label,
  defaultValue,
  min,
  max,
  children,
  ...props
}: IProps) {
  return (
    <FormSection {...props}>
      <Label htmlFor={id}>{label}</Label>
      <InputInteger
        id={id}
        name={name}
        min={min}
        max={max}
        defaultValue={defaultValue}
      />
      {children && <div>{children}</div>}
    </FormSection>
  );
}
