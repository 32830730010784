import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { isRegisteredAccount } from "#entities/account";

type IClientContext = undefined | { isRegistered: boolean };

const ClientContext = createContext<IClientContext>(undefined);

interface IProps {
  children?: ReactNode;
}

export function ClientProvider({ children }: IProps) {
  const [client, changeClient] = useState<IClientContext>();

  useEffect(() => {
    (async () => {
      const isRegistered = isRegisteredAccount();
      const clientData: IClientContext = { isRegistered };
      changeClient(clientData);
    })();
  }, []);

  return (
    <ClientContext.Provider value={client}>{children}</ClientContext.Provider>
  );
}

export function useClient(): IClientContext {
  const context = useContext(ClientContext);

  return context;
}
