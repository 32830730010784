import { createBlockComponent } from "#components/meta";
import { InputText } from "../inputs";
import { Label } from "../label";
import { FormSection } from "./section";
import { IBaseFormSectionProps } from "./types";

interface IProps extends IBaseFormSectionProps {}

export const FormSectionText = createBlockComponent(undefined, Component);

function Component({
  id,
  name,
  label,
  defaultValue,
  children,
  ...props
}: IProps) {
  return (
    <FormSection {...props}>
      <Label htmlFor={id}>{label}</Label>
      <InputText id={id} name={name} defaultValue={defaultValue} />
      {children && <div>{children}</div>}
    </FormSection>
  );
}
