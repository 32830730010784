import { ReactNode } from "react";
import { createBlockComponent } from "#components/meta";
import { FormFieldset } from "./fieldset";
import { IBaseFormFieldsetProps } from "./types";

import * as styles from "./radio-group.module.scss";

interface IProps extends Omit<IBaseFormFieldsetProps, "label"> {
  radios: (id: string, name: string) => ReactNode;
}

export const FormSectionRadioGroup = createBlockComponent(styles, Component);

function Component({ id, name, radios, children, ...props }: IProps) {
  return <FormFieldset {...props}>{radios(id, name)}</FormFieldset>;
}
