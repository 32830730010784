import {
  LoaderFunctionArgs,
  useLoaderData,
  useNavigate,
} from "react-router";
import { createFilePageURL } from "#lib/urls";
import { useEffect } from "react";

interface IProps {
  hash: string;
}
// doing this roundabout way because `redirect` returned from loaders
// doesn't overwrite history stack, even if redirect is "permanent"

export function LegacyFilePage() {
  const { hash } = useLoaderData() as IProps;
  const navigate = useNavigate();

  const url = String(createFilePageURL(hash));

  useEffect(() => {
    navigate(url, { replace: true });
  }, []);

  return <></>;
}

export async function loader({ params }: LoaderFunctionArgs) {
  const fileHash = params.file_hash?.trim();

  if (!fileHash) {
    throw new Error("File hash is required.");
  }

  const props: IProps = {
    hash: fileHash,
  };

  return props;
}
