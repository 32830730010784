import {
  ActionFunctionArgs,
  LoaderFunctionArgs,
  redirect,
  useLoaderData,
} from "react-router";
import { createAccountDetailsPageURL } from "#lib/urls";
import {
  apiChangeTargetAccountRole,
  apiFetchAccount,
} from "#api/account/administrator";
import {
  PageSkeleton,
  validateAdministratorPageAction,
  validateAdministratorPageLoader,
} from "#components/pages";
import { IAccount, ensureAccountRole } from "#entities/account";
import { AccountOverview } from "#entities/administrator";

import * as styles from "./account.module.scss";

interface IProps {
  account: IAccount;
}

export function AdministratorAccountOverviewPage() {
  const { account } = useLoaderData() as IProps;
  const title = "Account overview";
  const heading = "Account Overview";

  return (
    <PageSkeleton name="" title={title} heading={heading}>
      <AccountOverview className={styles.overview} account={account} />
    </PageSkeleton>
  );
}

export async function loader(args: LoaderFunctionArgs) {
  await validateAdministratorPageLoader(args);

  const { params } = args;

  const accountID = params.account_id?.trim();

  if (!accountID) {
    throw new Error("Account ID is required.");
  }

  const parsedAccountID = Number(accountID);

  if (parsedAccountID === 0) {
    throw new Error("Account ID must be positive.");
  }

  const account = await apiFetchAccount(parsedAccountID);

  const props: IProps = {
    account,
  };

  return props;
}

export async function action(args: ActionFunctionArgs) {
  try {
    await validateAdministratorPageAction(args);

    const { params, request } = args;

    const accountID = params.account_id?.trim();

    if (!accountID) {
      throw new Error("Account ID is required.");
    }

    const parsedAccountID = Number(accountID);

    if (parsedAccountID === 0) {
      throw new Error("Account ID must be positive.");
    }

    const data = await request.formData();

    const role = (data.get("role") as string | null)?.trim();

    if (!role) {
      throw new Error("Role is required.");
    }

    ensureAccountRole(role);

    const targetAccountID = await apiChangeTargetAccountRole(
      parsedAccountID,
      role
    );
    const url = String(createAccountDetailsPageURL(targetAccountID));

    return redirect(url);
  } catch (error) {
    return error;
  }
}
