import { ReactNode } from "react";
import clsx from "clsx";
import { IBlockProps, createBlockComponent } from "#components/meta";

export interface IFormFieldsetProps extends IBlockProps<"fieldset"> {
  legend: ReactNode;
}
interface ILegendProps extends IBlockProps<"legend"> {}

export const FormFieldset = createBlockComponent(undefined, FieldsetComponent);

function FieldsetComponent({
  className,
  legend,
  children,
  ...props
}: IFormFieldsetProps) {
  return (
    <fieldset className={clsx("form__fieldset", className)} {...props}>
      <Legend>{legend}</Legend>
      {children}
    </fieldset>
  );
}

function Legend({ ...props }: ILegendProps) {
  return <legend {...props} />;
}
