import { IPagination, createPagination } from "#lib/pagination";
import { LinkButton } from "#components/links";
import { DescriptionList, DescriptionSection } from "#components/lists";
import { ButtonSubmit, FormRouter, FormSection } from "#components/forms";
import { FormSectionInteger } from "#components/forms/sections";
import { InputHidden } from "#components/forms/inputs";

import * as styles from "./pagination.module.scss";

interface IProps {
  pagination: IPagination;
  action: string;
  constructURL: (page: number) => string;
  formID?: string;
  extraValues?: Record<string, string | undefined>;
}

export function Pagination({
  formID = "pagination",
  pagination,
  constructURL,
  action,
  extraValues,
}: IProps) {
  const { total_pages, current_page } = pagination;
  const previousPage = Math.max(current_page - 1, 1);
  const nextPage = Math.min(current_page + 1, total_pages);

  return (
    <div className={styles.block}>
      <ul className={styles.list}>
        <li className={styles.current}>
          {total_pages === 1 ? (
            <DescriptionList className={styles.single}>
              <DescriptionSection
                dKey="Current page"
                dValue={current_page}
                isValuePreformatted
              />
            </DescriptionList>
          ) : (
            <CurrentPage
              pagination={pagination}
              formID={formID}
              action={action}
              extraValues={extraValues}
            />
          )}
        </li>

        <li className={styles.previous}>
          {current_page === 1 ? (
            <span className={styles.page}>Previous</span>
          ) : (
            <LinkButton
              className={styles.link}
              url={constructURL(previousPage)}
            >
              Previous
            </LinkButton>
          )}
        </li>

        <li className={styles.next}>
          {current_page === total_pages ? (
            <span className={styles.page}>Next</span>
          ) : (
            <LinkButton className={styles.link} url={constructURL(nextPage)}>
              Next
            </LinkButton>
          )}
        </li>

        <li className={styles.first}>
          {current_page === 1 ? (
            <span className={styles.page}>First</span>
          ) : (
            <LinkButton className={styles.link} url={constructURL(1)}>
              First
            </LinkButton>
          )}
        </li>

        <li className={styles.last}>
          {current_page === total_pages ? (
            <span className={styles.page}>Last</span>
          ) : (
            <LinkButton className={styles.link} url={constructURL(total_pages)}>
              Last
            </LinkButton>
          )}
        </li>
      </ul>
    </div>
  );
}

interface ICurrentPageProps
  extends Pick<IProps, "pagination" | "action" | "formID" | "extraValues"> {}

function CurrentPage({
  pagination,
  action,
  formID,
  extraValues,
}: ICurrentPageProps) {
  const { current_page, total_pages } = pagination;
  const processedValues = !extraValues
    ? undefined
    : Object.entries(extraValues).filter(([_, value]) => value);

  return (
    <FormRouter
      className={styles.form}
      action={action}
      method="GET"
      statusSection={null}
    >
      {(state) => (
        <>
          <FormSectionInteger
            id={`${formID}-page`}
            className={styles.number}
            name="page"
            label="Current page"
            min={1}
            max={total_pages}
            defaultValue={current_page}
          />

          {processedValues?.map(([key, value], index) => (
            <InputHidden
              key={`${key}-${index}`}
              id={`${formID}-${key}`}
              name={key}
              value={value}
            />
          ))}

          <FormSection className={styles.submit}>
            <ButtonSubmit disabled={state !== "idle"}>Go</ButtonSubmit>
          </FormSection>
        </>
      )}
    </FormRouter>
  );
}
