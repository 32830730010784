import { InvalidErrorType } from "#lib/errors";
import { IResponseError } from "./types";

interface IAPIV2Error extends Error {
  pathSpec: string;
  request: Request;
  response: Response;
  error?: IResponseError;
}

interface IAPIV2ErrorOptions extends ErrorOptions {
  pathSpec: string;
  request: Request;
  response: Response;
  error?: IResponseError;
}

export class APIV2Error extends Error implements IAPIV2Error {
  pathSpec: string;
  request: Request;
  response: Response;
  error?: IResponseError;

  constructor(message: string, options: IAPIV2ErrorOptions) {
    super(message);

    this.pathSpec = options.pathSpec;
    this.request = options.request;
    this.response = options.response;
    this.error = options.error;
  }
}

export function isAPIV2Error(input: unknown): input is APIV2Error {
  return input instanceof APIV2Error;
}

export function ensureAPIV2Error(input: unknown): asserts input is APIV2Error {
  if (!isAPIV2Error(input)) {
    throw new InvalidErrorType(input);
  }
}
