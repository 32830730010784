import { IAnnouncement } from "./types";

import * as styles from "./announcement.module.scss";

interface IProps {
  announcement: IAnnouncement;
}

export function AnnouncementPreview({ announcement }: IProps) {
  const { content, published, added } = announcement;

  return (
    <article className={styles.block}>
      <section className={styles.body} tabIndex={0}>
        <div>
          <pre
            className={styles.content}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      </section>

      <footer>
        {published ? (
          <div>Published: {published.slice(0, 7)}</div>
        ) : (
          <div>Added: {added}</div>
        )}
      </footer>
    </article>
  );
}
