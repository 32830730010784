import { apiFetch } from "#lib/api";
import { IAccount } from "#entities/account";

interface IResult {
  props: {
    currentPage: "account";
    title: string;
    account: IAccount;
    notifications_count: number;
  };
}

export async function fetchAccount() {
  const path = "/account";

  const result = await apiFetch<IResult>(path, { method: "GET" });

  return result;
}
