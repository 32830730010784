import { apiFetch } from "#lib/api";
import { IFavouritePost } from "#entities/account";

export async function fetchFavouritePosts() {
  const path = `/account/favorites`;
  const params = new URLSearchParams([["type", "post"]]);

  const data = await apiFetch<IFavouritePost[]>(
    path,
    { method: "GET" },
    params
  );

  return data;
}
