import { apiFetch } from "#lib/api";
import { IPost } from "#entities/posts";

interface IResult {
  count: number;
  true_count: number;
  posts: IPost[];
}

export async function fetchPosts(
  offset?: number,
  query?: string,
  tags?: string[]
) {
  const path = "/posts";
  const params = new URLSearchParams();

  if (offset) {
    params.set("o", String(offset));
  }

  if (query) {
    params.set("q", query);
  }

  if (tags) {
    for (const tag of tags) {
      params.set("tag", tag);
    }
  }

  const result = await apiFetch<IResult>(path, { method: "GET" }, params);

  return result;
}
