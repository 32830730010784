import { Layout } from "#components/layout";
import { ErrorPage } from "#components/pages";
import { HomePage } from "#pages/home";
import { ImporterTutorialPage } from "#pages/importer/importer_tutorial";
import { ImporterTutorialFanboxPage } from "#pages/importer/importer_tutorial_fanbox";
import { ImporterPage } from "#pages/importer/importer_list";
import { SearchFilesPage } from "#pages/search_hash";
import { ImporterOKPage } from "#pages/importer/importer_ok";
import {
  AdministratorDashboardPage,
  loader as administratorDashboardPageLoader,
} from "#pages/account/administrator/dashboard";
import {
  AccountLoginPage,
  action as accountLoginPageAction,
} from "#pages/account/login";
import { ArtistsPage, loader as artistsPageLoader } from "#pages/profiles";
import {
  ArtistsUpdatedPage,
  loader as artistsUpdatedPageLoader,
} from "#pages/profiles/updated";
import { loader as artistRandomPageLoader } from "#pages/profiles/random";
import { ProfilePage, loader as profilePageLoader } from "#pages/profile";
import {
  ProfileTagsPage,
  loader as profileTagsPageLoader,
} from "#pages/profile/tags";
import {
  FancardsPage,
  loader as fancardsLoader,
} from "#pages/profile/fancards";
import {
  ProfileSharesPage,
  loader as profileSharesPageLoader,
} from "#pages/shares";
import {
  ProfileDMsPage,
  loader as profileDMsPageLoader,
} from "#pages/profile/dms";
import {
  AnnouncementsPage,
  loader as announcementsPageLoader,
} from "#pages/profile/announcements";
import {
  ProfileLinksPage,
  loader as profileLinksPageLoader,
} from "#pages/profile/linked_accounts";
import {
  NewProfileLinkPage,
  loader as newProfileLinkPageLoader,
  action as newProfileLinkPageAction,
} from "#pages/profile/new-linked-profile";
import { DMsPage, loader as dmsPageLoader } from "#pages/all_dms";
import { loader as accountFavoritesPageLoader } from "#pages/favorites";
import { SharePage, loader as sharePageLoader } from "#pages/share";
import { SharesPage, loader as sharesPageLoader } from "#pages/shares-all";
import { PostPage, loader as postPageLoader } from "#pages/post";
import { loader as postPageDataLoader } from "#pages/post/data";
import { PostsPage, loader as postsPageLoader } from "#pages/posts";
import {
  PopularPostsPage,
  loader as popularPostsPageLoader,
} from "#pages/posts/popular";
import { TagsPage, loader as tagsPageLoader } from "#pages/tags-all";
import {
  DiscordServerPage,
  loader as discordServerPageLoader,
} from "#pages/discord";
import {
  DiscordChannelPage,
  loader as discordChannelPageLoader,
} from "#pages/discord-channel";
import {
  ArchiveFilePage,
  loader as archiveFilePageLoader,
} from "#pages/file/archive";
import { loader as postRandomPageLoader } from "#pages/posts/random";
import {
  DMsReviewPage,
  loader as dmsReviewPageLoader,
  action as dmsReviewPageAction,
} from "#pages/review_dms/review_dms";
import {
  PostRevisionPage,
  loader as postRevisionPageLoader,
} from "#pages/post-revision";
import {
  ImporterStatusPage,
  loader as importerStatusPageLoader,
} from "#pages/importer/importer_status";
import { AccountPage, loader as accountPageLoader } from "#pages/account/home";
import {
  AccountNotificationsPage,
  loader as accountNotificationsPageLoader,
} from "#pages/account/notifications";
import {
  AccountAutoImportKeysPage,
  loader as accountAutoImportKeysPageLoader,
  action as accountAutoImportKeysPageAction,
} from "#pages/account/keys";
import {
  AccountChangePasswordPage,
  loader as accountChangePasswordPageLoader,
  action as accountChangePasswordPageAction,
} from "#pages/account/change_password";
import {
  AdministratorAccountsPage,
  loader as administratorAccountsPageLoader,
  baseLoader as administratorAccountsPageBaseLoader,
} from "#pages/account/administrator/accounts";
import {
  ModeratorDashboardPage,
  loader as moderatorDashboardPageLoader,
} from "#pages/account/moderator/dashboard";
import {
  ProfileLinkRequestsPage,
  loader as profileLinkRequestsPageLoader,
} from "#pages/account/moderator/profile_links";
import {
  RegisterPage,
  action as registerPageAction,
} from "#pages/account/register";
import { loader as accountLogoutPageLoader } from "#pages/authentication/logout";
import { loader as favoritesLegacyPageLoader } from "#pages/account/favorites/legacy";
import {
  FavoriteProfilesPage,
  loader as favoritesProfilesPageLoader,
} from "#pages/account/favorites/profiles";
import {
  FavoritePostsPage,
  loader as favoritesPostsPageLoader,
} from "#pages/account/favorites/posts";
import { Compliance2257Page } from "#pages/2257";
import { ContactPage } from "#pages/contact";
import { DMCAPage } from "#pages/dmca";
import { FanboxImportsPage } from "#pages/fanboximports";
import { GumroadAndCoPage } from "#pages/gumroad-and-co";
import { MatrixPage } from "#pages/matrix";
import {
  AdministratorAccountOverviewPage,
  loader as administratorAccountOverviewPageLoader,
  action as administratorAccountOverviewPageAction,
} from "#pages/account/administrator/account";
import {
  LegacyFilePage,
  loader as legacyFilePageLoader,
} from "#pages/file/legacy";
import { Error404 } from "#pages/errors/404";

export const routes = [
  {
    path: "/",
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        errorElement: <ErrorPage />,
        children: [
          { index: true, element: <HomePage /> },
          {
            path: "/2257",
            element: <Compliance2257Page />,
          },
          {
            path: "/contact",
            element: <ContactPage />,
          },
          {
            path: "/dmca",
            element: <DMCAPage />,
          },
          {
            path: "/fanboximports",
            element: <FanboxImportsPage />,
          },
          {
            path: "/gumroad-and-co",
            element: <GumroadAndCoPage />,
          },
          {
            path: "/matrix",
            element: <MatrixPage />,
          },
          {
            path: "/importer",
            element: <ImporterPage />,
          },
          {
            path: "/importer/ok",
            element: <ImporterOKPage />,
          },
          {
            path: "/importer/tutorial",
            element: <ImporterTutorialPage />,
          },
          {
            path: "/importer/tutorial_fanbox",
            element: <ImporterTutorialFanboxPage />,
          },
          {
            path: "/importer/status/:import_id",
            element: <ImporterStatusPage />,
            loader: importerStatusPageLoader,
          },
          {
            path: "/search_hash",
            element: <SearchFilesPage />,
          },
          {
            path: "/artists",
            element: <ArtistsPage />,
            loader: artistsPageLoader,
          },
          {
            path: "/artists/updated",
            element: <ArtistsUpdatedPage />,
            loader: artistsUpdatedPageLoader,
          },
          {
            path: "/artists/random",
            loader: artistRandomPageLoader,
          },
          {
            path: "/posts",
            element: <PostsPage />,
            loader: postsPageLoader,
          },
          {
            path: "/posts/popular",
            element: <PopularPostsPage />,
            loader: popularPostsPageLoader,
          },
          {
            path: "/posts/tags",
            element: <TagsPage />,
            loader: tagsPageLoader,
          },
          {
            path: "/posts/archives/:file_hash",
            element: <LegacyFilePage />,
            loader: legacyFilePageLoader,
          },
          {
            path: "/file/:file_hash",
            element: <ArchiveFilePage />,
            loader: archiveFilePageLoader,
          },
          {
            path: "/posts/random",
            loader: postRandomPageLoader,
          },
          {
            path: "/favorites",
            loader: favoritesLegacyPageLoader,
          },
          {
            path: "/discord/server/:server_id",
            element: <DiscordServerPage />,
            loader: discordServerPageLoader,
          },
          {
            path: "/discord/server/:server_id/:channel_id",
            element: <DiscordChannelPage />,
            loader: discordChannelPageLoader,
          },
          {
            path: "/:service/user/:creator_id",
            element: <ProfilePage />,
            loader: profilePageLoader,
          },
          {
            path: "/:service/user/:creator_id/tags",
            element: <ProfileTagsPage />,
            loader: profileTagsPageLoader,
          },
          {
            path: "/:service/user/:creator_id/fancards",
            element: <FancardsPage />,
            loader: fancardsLoader,
          },
          {
            path: "/:service/user/:creator_id/shares",
            element: <ProfileSharesPage />,
            loader: profileSharesPageLoader,
          },
          {
            path: "/:service/user/:creator_id/dms",
            element: <ProfileDMsPage />,
            loader: profileDMsPageLoader,
          },
          {
            path: "/:service/user/:creator_id/announcements",
            element: <AnnouncementsPage />,
            loader: announcementsPageLoader,
          },
          {
            path: "/:service/user/:creator_id/links",
            element: <ProfileLinksPage />,
            loader: profileLinksPageLoader,
          },
          {
            path: "/:service/user/:creator_id/post/:post_id",
            element: <PostPage />,
            loader: postPageLoader,
          },
          {
            path: "/:service/user/:creator_id/post/:post_id/revision/:revision_id",
            element: <PostRevisionPage />,
            loader: postRevisionPageLoader,
          },
          {
            path: "/:service/post/:post_id",
            loader: postPageDataLoader,
          },
          {
            path: "/dms",
            element: <DMsPage />,
            loader: dmsPageLoader,
          },
          {
            path: "/shares",
            element: <SharesPage />,
            loader: sharesPageLoader,
          },
          {
            path: "/share/:share_id",
            element: <SharePage />,
            loader: sharePageLoader,
          },
          {
            path: "/documentation/api",
            lazy: () => import("#pages/documentation/api"),
          },
          {
            path: "/authentication/register",
            element: <RegisterPage />,
            action: registerPageAction,
          },
          {
            path: "/authentication/login",
            element: <AccountLoginPage />,
            action: accountLoginPageAction,
          },
          {
            path: "/authentication/logout",
            loader: accountLogoutPageLoader,
          },
          {
            path: "/account",
            element: <AccountPage />,
            loader: accountPageLoader,
          },
          {
            path: "/account/favorites",
            loader: accountFavoritesPageLoader,
          },
          {
            path: "/account/favorites/artists",
            element: <FavoriteProfilesPage />,
            loader: favoritesProfilesPageLoader,
          },
          {
            path: "/account/favorites/posts",
            element: <FavoritePostsPage />,
            loader: favoritesPostsPageLoader,
          },
          {
            path: "/account/notifications",
            element: <AccountNotificationsPage />,
            loader: accountNotificationsPageLoader,
          },
          {
            path: "/account/keys",
            element: <AccountAutoImportKeysPage />,
            loader: accountAutoImportKeysPageLoader,
            action: accountAutoImportKeysPageAction,
          },
          {
            path: "/account/change_password",
            element: <AccountChangePasswordPage />,
            loader: accountChangePasswordPageLoader,
            action: accountChangePasswordPageAction,
          },
          {
            path: "/account/:service/user/:creator_id/links/new",
            element: <NewProfileLinkPage />,
            loader: newProfileLinkPageLoader,
            action: newProfileLinkPageAction,
          },
          {
            path: "/account/posts/upload",
            lazy: () => import("#pages/upload"),
          },
          {
            path: "/account/review_dms",
            element: <DMsReviewPage />,
            loader: dmsReviewPageLoader,
            action: dmsReviewPageAction,
          },
          {
            path: "/account/moderator",
            element: <ModeratorDashboardPage />,
            loader: moderatorDashboardPageLoader,
          },
          {
            path: "/account/moderator/tasks/creator_links",
            element: <ProfileLinkRequestsPage />,
            loader: profileLinkRequestsPageLoader,
          },
          {
            path: "/account/administrator",
            element: <AdministratorDashboardPage />,
            loader: administratorDashboardPageLoader,
          },
          {
            path: "/account/administrator/accounts",
            loader: administratorAccountsPageBaseLoader,
          },
          {
            path: "/account/administrator/accounts/:page",
            element: <AdministratorAccountsPage />,
            loader: administratorAccountsPageLoader,
          },
          {
            path: "/account/administrator/account/:account_id",
            element: <AdministratorAccountOverviewPage />,
            loader: administratorAccountOverviewPageLoader,
            action: administratorAccountOverviewPageAction,
          },
          {
            path: "/*",
            element: <Error404 />,
          }
        ],
      },
    ],
  },
];
