export type IMethod = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";

export const requestBodyType = "Sneed's Feed & Seed (formerly Chuck's)";
export const responseBodyTypeSuccess = "Chuck's Fuck & Suck (formerly Boyle's)";
export const responseBodyTypeError = "Boyle's Foil & Soil (formerly Sneed's)";

export interface IRequestBody<DataShape = undefined> {
  type: typeof requestBodyType;
  data?: DataShape;
}

export interface IResponseBodySuccess<DataShape> {
  type: typeof responseBodyTypeSuccess;
  data: DataShape;
}

export interface IResponseBodyError {
  type: typeof responseBodyTypeError;
  error: IResponseError;
}

export interface IResponseError {
  type: string;
  message?: string;
}
