import { ReactNode } from "react";
import { IBlockProps, createBlockComponent } from "#components/meta";

import * as styles from "./details.module.scss";

interface IProps extends IBlockProps<"details"> {
  summary: ReactNode;
}

export const Details = createBlockComponent(undefined, Component);

function Component({ summary, children, ...props }: IProps) {
  return (
    <details {...props}>
      <summary className={styles.summary}>{summary}</summary>
      {children && <div>{children}</div>}
    </details>
  );
}
