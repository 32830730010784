import { KemonoLink } from "#components/links";
import { useNavigate } from "react-router";
import * as styles from "./errors.module.scss";

export function Error404() {
  const navigate = useNavigate();

  function goBack() {
    navigate(-1);
  }

  return (
    <div className={styles.errorPage}>
      <h1>404</h1>
      <div>The page you are looking for does not exist.</div>
      <div>
        <KemonoLink url="/">Home</KemonoLink>{" | "}
        <KemonoLink url="#" onClick={goBack}>Go Back</KemonoLink>
      </div>
    </div>
  )
}
