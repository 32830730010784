import { apiFetch } from "#lib/api";
import { IApprovedDM } from "#entities/dms";

interface IResult {
  props: {
    currentPage: "artists";
    count: number;
    limit: number;
    dms: IApprovedDM[];
  };
  base: {};
}

export async function fetchDMs(offset?: number, query?: string) {
  const path = "/dms";
  const params = new URLSearchParams();

  if (offset) {
    params.set("o", String(offset));
  }

  if (query) {
    params.set("q", query);
  }

  const result = await apiFetch<IResult>(path, { method: "GET" }, params);

  return result;
}
