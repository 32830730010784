import { FormEvent, MouseEvent, useEffect, useRef, useState } from "react";
import { flagPost } from "#api/posts";
import { useClient } from "#hooks";
import { LoadingIcon } from "#components/loading";
import { Button } from "#components/buttons";
import { Modal } from "#components/modal";
import { IPostActionsProps } from "./types";

import * as styles from "./flag-button.module.scss";

const REASON_MESSAGES: {[reason: string]: string} = {
  "delete-copyright": `Post contains copyrighted material that should not be shared. We will review this post to ensure legal compliance and protect creators' rights.
                       After submitting the flag to finalize the request please contact us via the <a href="/contact">contact page.</a>`,
  "delete-abuse": `Use this flag if the post includes illegal material or content that violates the law in your country.
                   After submitting the flag to finalize the request please contact us via the <a href="/contact">contact page.</a>`,
  "missing-password": "Post files or urls requires a password to access content, but no password is provided in the post.",
  "offsite-expired": "If the post files rewards exist on an external link that no longer works, select this flag.",
  "post-changed": `Use this option if the original post has been edited at the source with new images or URLs, revised content, or updated index posts.`,
  "corrupted-files": "Images, videos, or other files that are broken or unreadable.",
  "missing-files": "The post is missing files that were expected to be included, such as images, attachments, or URLs.",
  "stale-comments": "The comments on the post are outdated and require reimport to give access to passwords or URLs.",
  "formatting-error": "Post text has formatting issues, such as text size difference, missing highlighting, incorrect spacing, or broken layout. Reporting this will help development.",
  "reason-other": "There was some other problem with this post.",
};

interface IFlagButtonProps
  extends Pick<
    IPostActionsProps,
    "flagged" | "service" | "profileID" | "postID"
  > {}

export function FlagButton({
  service,
  profileID: creatorId,
  postID: postId,
  flagged,
}: IFlagButtonProps) {
  const client = useClient();
  const [isFlagged, setFlagged] = useState(Boolean(flagged));
  const [isFlagging, setFlagging] = useState(false);
  // const [modalOpen, setModalOpen] = useState(false);
  // const [reason, setReason] = useState(flagged ?? "");
  // const [reasonDescription, setReasonDescription] = useState(REASON_MESSAGES[reason] ?? "Select a reason")

  useEffect(() => {
    setFlagged(Boolean(flagged));
  }, [service, creatorId, postId, flagged]);

  async function handleFlagging(flagReason: string) {
    try {
      setFlagging(true);

      let result = await flagPost({ service, creatorId, postId, reason: flagReason });
      if (result) {
        setFlagged(true);
        // setReason(result);
        // onModalClose();
      } else {
        setFlagged(false);
        alert("Error flagging");
      }
    } catch (e) {
      alert("Error flagging: " + e);
      setFlagged(false);
    } finally {
      setFlagging(false);
    }
  }

  // function showModal() {
  //   setModalOpen(true);
  // }

  // function onModalClose() {
  //   setModalOpen(false);
  // }

  // function submitFlag(event: MouseEvent<HTMLElement>) {
  //   event.preventDefault();
  //   handleFlagging(reason);
  // }

  // function cancelClicked(event: MouseEvent<HTMLElement>) {
  //   event.preventDefault();
  //   setModalOpen(false);
  // }

  // function reasonChanged(event: FormEvent) {
  //   let reasonId = (event.target as HTMLElement).id;
  //   setReason(reasonId);
  //   setReasonDescription(REASON_MESSAGES[reasonId] ?? "Invalid reason selected");
  // }

  function flagClicked(event: MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    handleFlagging("reason-other")
  }

  if (!client) {
    return (
      <LoadingIcon>Loading...</LoadingIcon>
    );
  } else if (!client.isRegistered) {
    return (
      <span className={styles.disabled} title="Flagging requires an account">⚑ Flag</span>
    );
  } else if (isFlagging) {
    return (
      <LoadingIcon>Flagging...</LoadingIcon>
    );
  } else if (isFlagged) {
    return (
      <span className={styles.disabled}>⚑ Flagged</span>
    );
  } else {
    return (
      <>
        <Button className={styles.button} disabled={isFlagging} onClick={flagClicked}>
          <span>
          {isFlagging ? <LoadingIcon /> : <>⚑ </>}
          {!isFlagged ? <>Flag</> : <>Flagged</>}
          </span>
        </Button>
        {/* <Modal isOpen={modalOpen} onClose={onModalClose} className={styles.modal}>
          <form>
            <section onChange={reasonChanged}>
              <div>
                <input id="delete-copyright" type="radio" name="reason" checked={reason == "delete-copyright"}></input>
                <label htmlFor="delete-copyright">Copyright Content</label>
              </div>
              <div>
                <input id="delete-abuse" type="radio" name="reason" checked={reason == "delete-abuse"}></input>
                <label htmlFor="delete-abuse">Illegal Content</label>
              </div>
              <div>
                <input id="missing-password" type="radio" name="reason" checked={reason == "missing-password"}></input>
                <label htmlFor="missing-password">Missing Password</label>
              </div>
              <div>
                <input id="offsite-expired" type="radio" name="reason" checked={reason == "offsite-expired"}></input>
                <label htmlFor="offsite-expired">Link Expired</label>
              </div>
              <div>
                <input id="post-changed" type="radio" name="reason" checked={reason == "post-changed"}></input>
                <label htmlFor="post-changed">Post Changed</label>
              </div>
              <div>
                <input id="corrupted-files" type="radio" name="reason" checked={reason == "corrupted-files"}></input>
                <label htmlFor="corrupted-files">Corrupted Files</label>
              </div>
              <div>
                <input id="missing-files" type="radio" name="reason" checked={reason == "missing-files"}></input>
                <label htmlFor="missing-files">Missing Files</label>
              </div>
              <div>
                <input id="stale-comments" type="radio" name="reason" checked={reason == "stale-comments"}></input>
                <label htmlFor="stale-comments">Stale Comments</label>
              </div>
              <div>
                <input id="formatting-error" type="radio" name="reason" checked={reason == "formatting-error"}></input>
                <label htmlFor="formatting-error">Text Formatting</label>
              </div>
              <div>
                <input id="reason-other" type="radio" name="reason" checked={reason == "reason-other"}></input>
                <label htmlFor="reason-other">Other</label>
              </div>
            </section>
            <section>
              <div>
                <label dangerouslySetInnerHTML={{__html: reasonDescription}}></label>
              </div>
              <div>
                <button className="button" onClick={cancelClicked}>Cancel</button>
                <button className="button" onClick={submitFlag} disabled={reason === ""}>Submit</button>
              </div>
            </section>
          </form>
        </Modal> */}
      </>
    );
  }
}
