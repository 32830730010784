import { apiFetch } from "#lib/api";
import { IArtistDetails } from "#entities/profiles";
import {
  IComment,
  IPost,
  IPostAttachment,
  IPostPreview,
  IPostRevision,
  IPostVideo,
} from "#entities/posts";

interface IResult {
  props: {
    currentPage: "revisions";
    service: string;
    artist: IArtistDetails;
    flagged: string | null;
    revisions: [number, IPostRevision][];
  };
  post: IPost;
  comments: IComment[];
  result_previews: IPostPreview[];
  result_attachments: IPostAttachment[];
  videos: IPostVideo[];
  archives_enabled: boolean;
}

export async function fetchPostRevision(
  service: string,
  profileID: string,
  postID: string,
  revisionID: string
) {
  const path = `/${service}/user/${profileID}/post/${postID}/revision/${revisionID}`;

  const result = await apiFetch<IResult>(path, { method: "GET" });

  return result;
}
