import { IBlockProps, createBlockComponent } from "#components/meta";

import * as styles from "./overview.module.scss";

export interface IOverviewProps extends IBlockProps<"article"> {}
export interface IOverviewHeaderProps extends IBlockProps<"header"> {}
export interface IOverviewBodyProps extends IBlockProps<"section"> {}
export interface IOverviewFooterProps extends IBlockProps<"footer"> {}

export const Overview = createBlockComponent(styles.block, OverviewComponent);
export const OverviewHeader = createBlockComponent(
  styles.header,
  OverviewHeaderComponent
);
export const OverviewBody = createBlockComponent(
  styles.body,
  OverviewBodyComponent
);
export const OverviewFooter = createBlockComponent(
  styles.footer,
  OverviewFooterComponent
);

function OverviewComponent({ ...props }: IOverviewProps) {
  return <article {...props} />;
}
function OverviewHeaderComponent({ ...props }: IOverviewHeaderProps) {
  return <header {...props} />;
}
function OverviewBodyComponent({ ...props }: IOverviewBodyProps) {
  return <section {...props} />;
}
function OverviewFooterComponent({ ...props }: IOverviewFooterProps) {
  return <footer {...props} />;
}
