import clsx from "clsx";
import { ReactNode } from "react";
import { useClient } from "#hooks";
import { LoadingIcon } from "../loading/loading_icon";

interface IProps {
  time: string;
  isRelative?: boolean;
  className?: string;
  children?: ReactNode;
}

export function Timestamp({ time, isRelative, className, children }: IProps) {
  if (time === null) {
    return;
  }

  const isClient = useClient();
  let dateTime = new Date(time);
  let formatted = `${dateTime.getFullYear()}-${(dateTime.getMonth() + 1).toString().padStart(2, "0")}-${dateTime.getDate().toString().padStart(2, "0")}`;

  return (
    <time className={clsx("timestamp", className)} dateTime={time} title={time}>
      {!isClient ? <LoadingIcon /> : children ?? formatted}
    </time>
  );
}
