import { FetchError } from "#lib/fetch";
import { DescriptionList, DescriptionSection } from "#components/lists";

interface IProps {
  routePathPattern: string;
  error: FetchError;
}

export function FetchErrorView({ routePathPattern, error }: IProps) {
  const { name, message, request } = error;
  const { url, method } = request;

  return (
    <DescriptionList>
      <DescriptionSection
        dKey="Originated from"
        dValue="Client (this browser and/or this site's scripts)"
        isValuePreformatted
      />

      <DescriptionSection
        dKey="Page"
        dValue={routePathPattern}
        isValuePreformatted
      />

      <DescriptionSection
        dKey="Name"
        dValue={name}
        isHorizontal
        isValuePreformatted
      />

      <DescriptionSection dKey="Message" dValue={message} isValuePreformatted />

      <DescriptionSection dKey="URL" dValue={url} isValuePreformatted />

      <DescriptionSection
        dKey="Method"
        dValue={method}
        isHorizontal
        isValuePreformatted
      />
    </DescriptionList>
  );
}
