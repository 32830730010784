import clsx from "clsx";
import { IBlockProps, createBlockComponent } from "#components/meta";

export interface IFormSectionProps extends IBlockProps<"div"> {}

export const FormSection = createBlockComponent(undefined, Component);

function Component({ className, ...props }: IFormSectionProps) {
  return <div className={clsx("form__section", className)} {...props} />;
}
