import { apiFetch } from "#lib/api";

interface IResult
  extends Array<{
    id: string;

    public_id: string | null;
    service: string;
    name: string;

    indexed: string;

    updated: string;
  }> {}

export async function fetchProfileLinks(service: string, profileID: string) {
  const path = `/${service}/user/${profileID}/links`;
  const links = await apiFetch<IResult>(path, { method: "GET" });

  return links;
}
