import { apiFetch } from "#lib/api";
import { IArtist } from "#entities/profiles";

interface IResult {
  message: string
  props: {
    id: string
    service: string
    artist: IArtist
    share_count: number
    has_links: "✔️" | "0"
    display_data: {
      service: string
      href: string
    }
  }
}

interface IBody {
  service: string;
  artist_id: string;
  reason?: string;
}

export async function fetchAddProfileLink(
  service: string,
  profileID: string,
  linkService: string,
  linkProfileID: string,
  reason?: string
) {
  const path = `/${service}/user/${profileID}/links/new`;
  const body: IBody = {
    service: linkService,
    artist_id: linkProfileID,
    reason,
  };

  const result = await apiFetch<IResult>(path, { method: "POST", body });

  return result;
}
