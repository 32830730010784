import { LoaderFunctionArgs, useLoaderData } from "react-router";
import { parseOffset } from "#lib/pagination";
import { createPostsPageURL } from "#lib/urls";
import { fetchPosts } from "#api/posts";
import { PageSkeleton } from "#components/pages";
import { Paginator } from "#components/pagination";
import { FooterAd, HeaderAd, SliderAd } from "#components/advs";
import { CardList, PostCard } from "#components/cards";
import { ButtonSubmit, FormRouter } from "#components/forms";
import { IPost } from "#entities/posts";
import { findFavouritePosts, findFavouriteProfiles } from "#entities/account";
import { useRef, useState } from "react";

interface IProps {
  count: number;
  trueCount: number;
  offset?: number;
  posts: IPost[];
  query?: string;
  tags?: string[];
}

export function PostsPage() {
  const { count, trueCount, offset, query, posts, tags } =
    useLoaderData() as IProps;
  const [isLoading, setIsLoading] = useState(false);
  const title = "Posts";
  const heading = "Posts";

  return (
    <PageSkeleton name="posts" title={title} heading={heading}>
      <SliderAd />

      <HeaderAd />

      <div className="paginator" id="paginator-top">
        <SearchForm
          query={query}
          tags={tags}
          onLoadingChange={(loading) => setIsLoading(loading)}
        />

        <Paginator
          count={count}
          true_count={trueCount}
          offset={offset}
          constructURL={(offset) =>
            String(createPostsPageURL(offset, query, tags))
          }
        />
      </div>

      <CardList className={isLoading ? "card-list--loading" : ""}>
        {count === 0 ? (
          <div className="card-list__item--no-results">
            <h2 className="subtitle">Nobody here but us chickens!</h2>
            <p className="subtitle">There are no posts for your query.</p>
          </div>
        ) : (
          posts.map((post) => (
            <PostCard
              key={`${post.id}-${post.service}`}
              post={post}
              isFavourite={post.isFavourite}
              isFavouriteProfile={post.isFavouriteProfile}
            />
          ))
        )}
      </CardList>

      <div className="paginator" id="paginator-bottom">
        <Paginator
          count={count}
          true_count={trueCount}
          offset={offset}
          constructURL={(offset) =>
            String(createPostsPageURL(offset, query, tags))
          }
        />
      </div>

      <FooterAd />
    </PageSkeleton>
  );
}

interface ISearchFormProps
  extends Pick<IProps, "query" | "tags"> {
  onLoadingChange: (loading: boolean) => void;
}

// TODO: TAGS! add tag search!!
function SearchForm({ query, tags, onLoadingChange }: ISearchFormProps) {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);

    const target = e.currentTarget as HTMLInputElement;

    onLoadingChange(true);

    timeoutRef.current = setTimeout(() => {
      if (target.form) target.form.requestSubmit();
      onLoadingChange(false);
    }, 1000);
  };

  return (
    <FormRouter
      id="search-form"
      className="search-form"
      method="GET"
      autoComplete="off"
      noValidate={true}
      acceptCharset="UTF-8"
      statusSection={null}
      submitButton={undefined}
      style={{ maxWidth: "fit-content" }}
    >
      {(state) => (
        <>
          <div className="wrapper">
            <input
              type="text"
              name="q"
              id="q"
              autoComplete="off"
              defaultValue={query}
              minLength={2}
              placeholder="Search..."
              onChange={onInputChange}
            />
            <ButtonSubmit
              disabled={state === "loading" || state === "submitting"}
              className="search-button"
              onClick={() => {
                if (timeoutRef.current) clearTimeout(timeoutRef.current);
                onLoadingChange(false);
              }}
            >
              <img src='/static/menu/search.svg' />
            </ButtonSubmit>
          </div>
        </>
      )}
    </FormRouter>
  );
}

export async function loader({ request }: LoaderFunctionArgs): Promise<IProps> {
  const searchParams = new URL(request.url).searchParams;

  let offset: number | undefined = undefined;
  {
    const parsedOffset = searchParams.get("o")?.trim();

    if (parsedOffset) {
      offset = parseOffset(parsedOffset);
    }
  }

  const query = searchParams.get("q")?.trim();
  const tags = searchParams.getAll("tag");
  const { count, true_count, posts } = await fetchPosts(offset, query, tags);
  const postsData = posts.map(({ service, user, id }) => ({
    service,
    user,
    id,
  }));
  const profilesData = posts.reduce<{ service: string; id: string }[]>(
    (profilesData, post) => {
      const match = profilesData.find(
        (profileData) =>
          profileData.id === post.user && profileData.service === post.service
      );

      if (!match) {
        profilesData.push({ service: post.service, id: post.user });
      }

      return profilesData;
    },
    []
  );
  const favPosts = await findFavouritePosts(postsData);
  const favProfiles = await findFavouriteProfiles(profilesData);
  const postsWithFavs = posts.map<IPost>((post) => {
    const isFavPost = Boolean(
      favPosts.find(
        ({ service, user, id }) =>
          id === post.id && user === post.user && service === post.service
      )
    );
    const isFavProfile = Boolean(
      favProfiles.find(
        ({ service, id }) => id === post.user && service === post.service
      )
    );

    if (!isFavPost && !isFavProfile) {
      return post;
    }

    return {
      ...post,
      isFavourite: isFavPost,
      isFavouriteProfile: isFavProfile,
    };
  });

  return {
    offset,
    query,
    tags,
    count,
    trueCount: true_count,
    posts: postsWithFavs,
  };
}
