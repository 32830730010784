import { apiFetch } from "#lib/api";
import { INotification } from "#entities/account";

interface IResult {
  props: {
    currentPage: "account";
    notifications: INotification[];
  };
}

export async function fetchAccountNotifications() {
  const path = "/account/notifications";

  const result = await apiFetch<IResult>(path, { method: "GET" });

  return result.props;
}
