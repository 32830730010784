import { IAccount } from "#entities/account";
import { InternalURL } from "./internal-url";

export function createAccountsPageURL(
  page?: number,
  name?: string,
  role?: string
) {
  const path = !page
    ? `/account/administrator/accounts`
    : `/account/administrator/accounts/${page}`;
  const params = new URLSearchParams();

  if (name) {
    params.set("name", name);
  }

  if (role) {
    params.set("role", role);
  }

  return new InternalURL(path, params);
}

export function createAccountDetailsPageURL(id: IAccount["id"]) {
  const path = `/account/administrator/account/${id}`;

  return new InternalURL(path);
}
